import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import ImpersonatorIcon from '@material-ui/icons/RemoveRedEye';
import { injectIntl, defineMessages } from 'react-intl';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withOpenability, { openableShape } from '@unity/react-components/hoc/with-openability';
import HeaderIconButton from '@unity/react-components/header/header-icon-button';
import Button from '@unity/react-components/button';
import toObject from 'src/lib/to-object/to-object';
import Typography from '@unity/react-components/typography';
import impersonatorActions from 'src/store/ui/impersonator/impersonator-actions';
import userSelectors from 'src/store/entities/user/user-selectors';
import organizationSelectors from 'src/store/entities/organization/organization-selectors';
import { ADMIN_ROLES, LOCAL_DOMAIN, STAGING_DOMAIN, ROLES, ORGANIZATION_ROLES } from 'src/constants/app-constants';


const SHADOW_WITHOUT_TOP_SHADOW = '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14)';

const ADMIN_ROLES_ARRAY = Object.values(ADMIN_ROLES);
const ORGANIZATIONAL_ROLES_ARRAY = Object.values(ORGANIZATION_ROLES);

const renderSelectionLabel = (actualRoles, role) => (
  <>
    {role}
    {actualRoles.includes(role) && (
      <Typography variant="caption">
        actual
      </Typography>
    )}
  </>
);

export const messages = defineMessages({
  openImpersonator: {
    id: 'header.openImpersonator',
    defaultMessage: 'Open Impersonator',
  },
});

const styles = ({ mixins, constants, spacing, palette, breakpoints, unityTypography }) => ({
  '@keyframes fire': {
    '0%': {
      color: '#DC143C',
    },
    '25%': {
      color: '#ce4320',
    },
    '50%': {
      color: '#ffd700',
    },
    '75%': {
      color: '#ce4320',
    },
    '100%': {
      color: '#DC143C',
    },
  },
  popover: {
    ...mixins.position('fixed', constants.headerHeight, 0, null, null),
    maxHeight: 'calc(100vh - 150px)', // Offset for header and admin header height
    boxShadow: SHADOW_WITHOUT_TOP_SHADOW,
    borderTop: `1px solid ${palette.divider}`,
    borderRadius: 0,
    width: 500,

    [breakpoints.down('xs')]: {
      width: 290,
    },
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    padding: spacing(2),
    backgroundColor: palette.background.paper,
    borderTop: `1px solid ${palette.divider}`,

    '&:last-child': {
      paddingBottom: spacing(2),
    },
  },
  headerButtonIconActive: {
    animation: '$fire 3.5s infinite linear',
  },
  radio: {
    marginRight: spacing(4),
  },
  checkboxGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 220px)',
  },
  formControlLabel: {
    color: unityTypography.body.color,
  },
});

export class Impersonator extends React.Component {
  getRoles = () => {
    const organizationRoles = get(this.props.organization, '_roles', []);
    const userRoles = get(this.props.user, '_roles', []);
    return [...userRoles, ...organizationRoles].filter((role) => ROLES.includes(role));
  };

  getActualRoles() {
    const userRoles = this.getRoles();
    return Object.keys(this.props.roles).filter((role) => userRoles.includes(role));
  }

  handleAdminRoleChange = (role) => (event) => {
    this.props.actions.setRoles({ [role]: event.target.checked });
  };

  handleOrganizationRoleChange = (event) => {
    this.props.actions.setRoles({
      ...toObject(ORGANIZATIONAL_ROLES_ARRAY, () => false),
      [event.target.value]: true,
    });
  };

  handleFeatureChange = (feature) => (event) => {
    this.props.actions.setFeatures({ [feature]: event.target.checked });
  };

  handleReset = () => {
    this.props.actions.reset(this.props.organization);
  };

  isImpersonatorVisible() {
    const { organization } = this.props;
    const isOrganizationDataPresent = Boolean(get(organization, '_id'));
    const isDevEnvironment = [LOCAL_DOMAIN, STAGING_DOMAIN].includes(window.location.hostname);
    const isAdmin = this.getRoles().includes(ADMIN_ROLES.ADMIN);
    return isOrganizationDataPresent && (isAdmin || isDevEnvironment);
  }

  renderOrganizationRoles() {
    const { roles, classes } = this.props;
    const actualRoles = this.getActualRoles();
    const selectedOrganizationRole = ORGANIZATIONAL_ROLES_ARRAY.find((role) => roles[role]);

    return (
      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend">Organization role</FormLabel>
        <RadioGroup name="organizationRole" value={selectedOrganizationRole} onChange={this.handleOrganizationRoleChange} row>
          {ORGANIZATIONAL_ROLES_ARRAY.map((role) => (
            <FormControlLabel
              key={role}
              className={classes.radio}
              classes={{ label: classes.formControlLabel }}
              label={renderSelectionLabel(actualRoles, role)}
              value={role}
              control={(<Radio color="primary" />)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  renderAdminRoles() {
    const { roles, classes } = this.props;
    const actualRoles = this.getActualRoles();

    return (
      <FormControl margin="normal" fullWidth>
        <FormLabel>Admin roles</FormLabel>
        <section className={classes.checkboxGroup}>
          {ADMIN_ROLES_ARRAY.map((role) => (
            <FormGroup key={role}>
              <FormControlLabel
                classes={{ label: classes.formControlLabel }}
                label={renderSelectionLabel(actualRoles, role)}
                control={(
                  <Checkbox
                    checked={roles[role]}
                    onChange={this.handleAdminRoleChange(role)}
                    color="primary"
                    value={role}
                  />
                )}
              />
            </FormGroup>
          ))}
        </section>
      </FormControl>
    );
  }

  renderFeatureFlags() {
    const { features, classes } = this.props;
    return (
      <FormControl margin="normal" fullWidth>
        <FormLabel>Features</FormLabel>
        {Object.entries(features).map(([feature, isEnabled]) => (
          <FormGroup key={feature} row>
            <FormControlLabel
              label={feature}
              classes={{ label: classes.formControlLabel }}
              control={(
                <Checkbox
                  checked={isEnabled}
                  onChange={this.handleFeatureChange(feature)}
                  color="primary"
                  value={feature}
                />
              )}
            />
          </FormGroup>
        ))}
      </FormControl>
    );
  }

  render() {
    const { classes, openable, isImpersonating, intl } = this.props;

    if (!this.isImpersonatorVisible()) return null;

    const impersonatorButtonClass = classNames({
      [classes.headerButtonIconActive]: isImpersonating,
    });

    return (
      <>
        <HeaderIconButton
          icon={ImpersonatorIcon}
          onClick={openable.handleOpen}
          classes={{ icon: impersonatorButtonClass }}
          aria-label={intl.formatMessage(messages.openImpersonator)}
        />
        <Popover
          open={openable.open}
          anchorEl={openable.anchorEl}
          onClose={openable.handleClose}
          anchorOrigin={{ vertical: 48, horizontal: 40 }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{ paper: classes.popover }}
        >
          <CardContent>
            {this.renderOrganizationRoles()}
            {this.renderAdminRoles()}
            {this.renderFeatureFlags()}
          </CardContent>
          <Divider />
          <CardContent className={classes.footer}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleReset}
              disabled={!isImpersonating}
              fullWidth
            >
              {isImpersonating ? 'Stop impersonating' : 'Currently not impersonating'}
            </Button>
          </CardContent>
        </Popover>
      </>
    );
  }
}

Impersonator.propTypes = {
  actions: PropTypes.shape({
    reset: PropTypes.func.isRequired,
    setFeatures: PropTypes.func.isRequired,
    setRoles: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isImpersonating: PropTypes.bool.isRequired,
  openable: openableShape.isRequired,
  roles: PropTypes.object.isRequired,
  organization: PropTypes.object,
  user: PropTypes.object,
};

Impersonator.defaultProps = {
  organization: null,
  user: null,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    reset: impersonatorActions.reset,
    setRoles: impersonatorActions.setRoles,
    setFeatures: impersonatorActions.setFeatures,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => {
  const coreOrganizationId = ownProps.match.params.coreOrganizationId
    || state.users.entities.me.selectedOrganization;
  return {
    roles: state.uiImpersonator.roles,
    features: state.uiImpersonator.features,
    isImpersonating: state.uiImpersonator.isImpersonating,
    user: userSelectors.getCurrentUser(state, ownProps),
    organization: organizationSelectors.getOrganizationByCoreId(state, coreOrganizationId),
  };
};

export default compose(
  injectIntl,
  withRouter,
  withOpenability(),
  withStyles(styles, { name: 'Impersonator' }),
  connect(mapStateToProps, mapDispatchToProps),
)(Impersonator);
