import thunk from 'redux-thunk';
import localForage from 'localforage';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PERSIST_KEY } from 'src/constants/app-constants';
import entityTTL from './entity-ttl';
import persistTransformer from './utils/persist-transformer';
import rootReducer from './root-reducer';


const persistConfig = {
  key: PERSIST_KEY,
  storage: localForage,
  whitelist: Object.keys(entityTTL),
  transforms: [persistTransformer],
};

const configureStore = (initialState) => {
  const middleware = [
    thunk,
  ];

  const enhancers = [
    applyMiddleware(...middleware),
    typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  ];

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, initialState, compose(...enhancers));
  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept('./root-reducer', () => {
      try {
        store.replaceReducer(rootReducer);
      } catch (err) {
        console.error(`Reducer hot reloading error ${err}`); // eslint-disable-line no-console
      }
    });
  }

  return { store, persistor };
};

export default configureStore;
