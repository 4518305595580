import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { shouldFetchEntity } from './caching-helpers';


export const ERR_REQUEST_INVALID = "The 'request' parameter must be a valid function";
export const ERR_ID_INVALID = "The '$id' parameter must be a valid string";
export const ERR_COLLECTION_INVALID = "The 'collection' parameter must be a valid string";

export const getActionTypesFor = (prefix) => ({
  createError: `${prefix}_CREATE_ERROR`,
  createPending: `${prefix}_CREATE_PENDING`,
  createSuccess: `${prefix}_CREATE_SUCCESS`,
  deleteError: `${prefix}_DELETE_ERROR`,
  deletePending: `${prefix}_DELETE_PENDING`,
  deleteSuccess: `${prefix}_DELETE_SUCCESS`,
  getAllError: `${prefix}_FETCH_ALL_ERROR`,
  getAllPending: `${prefix}_FETCH_ALL_PENDING`,
  getAllSuccess: `${prefix}_FETCH_ALL_SUCCESS`,
  getOneError: `${prefix}_FETCH_ONE_ERROR`,
  getOnePending: `${prefix}_FETCH_ONE_PENDING`,
  getOneSuccess: `${prefix}_FETCH_ONE_SUCCESS`,
  updateError: `${prefix}_UPDATE_ERROR`,
  updatePending: `${prefix}_UPDATE_PENDING`,
  updateSuccess: `${prefix}_UPDATE_SUCCESS`,
});

export const getActionCreatorsFor = (prefix) => {
  const types = getActionTypesFor(prefix);
  return {
    createPending: (data) => ({
      type: types.createPending,
      data,
    }),
    createSuccess: (data) => ({
      type: types.createSuccess,
      data,
    }),
    createError: (data) => ({
      type: types.createError,
      data,
    }),
    deletePending: (data) => ({
      type: types.deletePending,
      data,
    }),
    deleteSuccess: (data) => ({
      type: types.deleteSuccess,
      data,
    }),
    deleteError: (data) => ({
      type: types.deleteError,
      data,
    }),
    getAllError: (data) => ({
      type: types.getAllError,
      data,
    }),
    getAllPending: (data) => ({
      type: types.getAllPending,
      data,
    }),
    getAllSuccess: (data) => ({
      type: types.getAllSuccess,
      data,
    }),
    getOneError: (data) => ({
      type: types.getOneError,
      data,
    }),
    getOnePending: (data) => ({
      type: types.getOnePending,
      data,
    }),
    getOneSuccess: (data) => ({
      type: types.getOneSuccess,
      data,
    }),
    updateError: (data) => ({
      type: types.updateError,
      data,
    }),
    updatePending: (data) => ({
      type: types.updatePending,
      data,
    }),
    updateSuccess: (data) => ({
      type: types.updateSuccess,
      data,
    }),
  };
};

export const getApiActions = (actionCreators) => ({
  create: ({ request }) => {
    if (!isFunction(request)) throw new Error(ERR_REQUEST_INVALID);

    return async (dispatch) => {
      try {
        dispatch(actionCreators.createPending());
        const result = await request();
        dispatch(actionCreators.createSuccess(result));
        return result;
      } catch (err) {
        dispatch(actionCreators.createError({ error: err }));
        throw err;
      }
    };
  },

  deleteOne: ({ request, $id, coreOrganizationId }) => async (dispatch) => {
    if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);
    if (!$id || !isString($id)) throw new Error(ERR_ID_INVALID);

    try {
      dispatch(actionCreators.deletePending({ $id }));
      const result = await request();
      dispatch(actionCreators.deleteSuccess({ $id, coreOrganizationId }));
      return result;
    } catch (err) {
      dispatch(actionCreators.deleteError({ $id, error: err }));
      throw err;
    }
  },

  update: ({ request, $id }) => async (dispatch) => {
    if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);
    if (!$id || !isString($id)) throw new Error(ERR_ID_INVALID);

    try {
      dispatch(actionCreators.updatePending({ $id }));
      const result = await request();
      dispatch(actionCreators.updateSuccess(result));
      return result;
    } catch (err) {
      dispatch(actionCreators.updateError({ $id, error: err }));
      throw err;
    }
  },

  getOne: ({ request, collection, $id, forceFetch = false }) => async (dispatch, getState) => {
    if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);
    if (!$id || !isString($id)) throw new Error(ERR_ID_INVALID);
    if (!collection || !isString(collection)) throw new Error(ERR_COLLECTION_INVALID);

    const locallyStoredEntities = getState()[collection];
    const existingEntity = locallyStoredEntities && locallyStoredEntities.entities[$id];

    if (shouldFetchEntity(existingEntity, forceFetch)) {
      try {
        dispatch(actionCreators.getOnePending($id));
        const result = await request();
        dispatch(actionCreators.getOneSuccess(result));
        return result;
      } catch (err) {
        dispatch(actionCreators.getOneError({ $id, error: err }));
        throw err;
      }
    } else {
      dispatch(actionCreators.getOneSuccess(existingEntity));
      return existingEntity;
    }
  },

  getAll: ({ request }) => async (dispatch) => {
    if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);

    try {
      dispatch(actionCreators.getAllPending());
      const result = await request();
      dispatch(actionCreators.getAllSuccess(result));
      return result;
    } catch (err) {
      dispatch(actionCreators.getAllError({ error: err }));
      throw err;
    }
  },
});
