import getEnvConfig from 'src/lib/env-config';


const appId = getEnvConfig().intercomAppId;

const intercomApp = {
  boot: (username, userEmail, userId, userHash) => {
    window.Intercom('boot', {
      app_id: appId,
      name: username,
      email: userEmail,
      user_id: userId,
      user_hash: userHash,
      custom_launcher_selector: '#intercom-toggler',
      hide_default_launcher: false,
    });
  },

  shutdown: () => window.Intercom('shutdown'),

  update: (updates) => window.Intercom('update', updates),

  show: () => window.Intercom('show'),

  hide: () => window.Intercom('hide'),

  onShow: (cb) => window.Intercom('onShow', cb),

  onHide: (cb) => window.Intercom('onHide', cb),

  onUnreadCountChange: (cb) => window.Intercom('onUnreadCountChange', cb),

  trackEvent: (eventName, metadata) => window.Intercom('trackEvent', eventName, metadata),
};

// Copied from https://www.intercom.com/help/install-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app
/* eslint-disable */
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    function l() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + appId;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();
/* eslint-enable */

export default intercomApp;

