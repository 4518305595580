import jsCookie from 'js-cookie';
import localForage from 'localforage';
import { PERSIST_KEY } from 'src/constants/app-constants';
import tokenConfig from 'src/lib/token-name-config';


const auth = {
  getTokenConfig: () => tokenConfig
    .find(({ uiDomain }) => uiDomain === window.location.hostname) || tokenConfig[0],

  getToken: () => {
    const { name } = auth.getTokenConfig();
    return jsCookie.get(name);
  },

  removeToken: () => {
    const { name, cookieDomain: domain } = auth.getTokenConfig();
    jsCookie.remove(name, { domain });
  },

  removeAppStateCache: () => {
    localForage.removeItem(`persist:${PERSIST_KEY}`);
  },

  logout: () => {
    auth.removeAppStateCache();
    window.location.assign('/auth/logout');
  },

  redirectToGenesisAuth: () => {
    const currentLocation = window.location.href;
    const redirectUrl = `/auth/unity?redirectTo=${btoa(currentLocation)}`;

    auth.removeAppStateCache();
    auth.removeToken();
    window.location.assign(redirectUrl);
  },
};

export default auth;
