import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import PageNotFound from 'src/components/errors/error-404';
import { publicRoutes } from 'src/app/routes';


export const PublicAppRoutes = () => (
  <Switch>
    {Object.entries(publicRoutes).map(([name, routeProps]) => (
      <Route
        key={name}
        path={routeProps.path}
        exact={routeProps.exact}
        render={(routerProps) => (
          <Route {...routerProps} {...routeProps} />
        )}
      />
    ))}
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default withRouter(PublicAppRoutes);
