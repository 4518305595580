import axios from 'axios';
import get from 'lodash/get';
import { StatusCodes } from 'http-status-codes';
import isValid from 'src/lib/token-validator/token-validator';
import auth from 'src/lib/auth/auth';
import getEnvConfig from 'src/lib/env-config';


const { unityServicesGatewayBaseUrl } = getEnvConfig();
export const instance = axios.create({
  baseURL: unityServicesGatewayBaseUrl,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});

export const interceptor = {
  auth,
  isValid,

  request: instance.interceptors.request.use((config) => {
    const token = auth.getToken();
    try {
      interceptor.isValid(token);
    } catch (e) {
      interceptor.auth.redirectToGenesisAuth();
      return Promise.reject();
    }
    return config;
  }),


  response: instance.interceptors.response.use((response) => response,
    (err) => {
      const error = {
        ...err,
        apiError: {
          status: get(err, 'response.status'),
          data: get(err, 'response.data'),
          request: get(err, 'response.request'),
        },
      };

      if (err.response?.status === StatusCodes.UNAUTHORIZED) {
        interceptor.auth.redirectToGenesisAuth();
      }

      return Promise.reject(error);
    }),
};

export default instance;
