import jwtDecode from 'jwt-decode';


const TOKEN_EXPIRED_ERROR = 'Token expired';
const TOKEN_INVALID_ERROR = 'Token invalid';

const isValid = (token) => {
  try {
    jwtDecode(token);
  } catch (e) {
    throw new Error(TOKEN_INVALID_ERROR);
  }

  const now = Math.floor(new Date().getTime() / 1000);
  const expiration = jwtDecode(token).exp;
  if (expiration < now) {
    throw new Error(TOKEN_EXPIRED_ERROR);
  }
};

export default isValid;
