import { ADMIN_BAR_OPEN, ADMIN_BAR_CLOSE } from './admin-bar-actions';


export const getInitialState = () => ({
  isOpen: false,
});

const modalReducer = (state = getInitialState(), { type }) => {
  switch (type) {
    case ADMIN_BAR_OPEN:
      return { ...state, isOpen: true };
    case ADMIN_BAR_CLOSE:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default modalReducer;
