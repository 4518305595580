import { LOCAL_DOMAIN, STAGING_DOMAIN, PRODUCTION_DOMAIN } from 'src/constants/app-constants';


export const configsByOrigin = {
  [LOCAL_DOMAIN]: {
    dataDomainScriptID: 'd6fa76ae-d285-4b34-afe6-5300dfd13650-test',
    intercomAppId: 'mbam64jh',
    accountManagementUrl: 'https://id-staging.unity.com',
    organizationManagementUrl: 'https://id-staging.unity.com/organizations',
    pspBackendDomain: 'localhost:6666',
    skanBackendUrl: 'https://skan.stg.mz.internal.unity3d.com/',
    unityServicesGatewayBaseUrl: 'https://staging.services.unity.com/api/',
  },
  [STAGING_DOMAIN]: {
    dataDomainScriptID: 'd6fa76ae-d285-4b34-afe6-5300dfd13650-test',
    intercomAppId: 'mbam64jh',
    accountManagementUrl: 'https://id-staging.unity.com',
    organizationManagementUrl: 'https://id-staging.unity.com/organizations',
    pspBackendDomain: 'psp-api.stg.mz.internal.unity3d.com',
    skanBackendUrl: 'https://skan.stg.mz.internal.unity3d.com/',
    unityServicesGatewayBaseUrl: 'https://staging.services.unity.com/api/',
  },
  [PRODUCTION_DOMAIN]: {
    dataDomainScriptID: 'd6fa76ae-d285-4b34-afe6-5300dfd13650',
    intercomAppId: 'cjtnalj7',
    accountManagementUrl: 'https://id.unity.com',
    organizationManagementUrl: 'https://id.unity.com/organizations',
    pspBackendDomain: 'psp-api.prd.mz.internal.unity3d.com',
    skanBackendUrl: 'https://skan.mz.unity3d.com/',
    unityServicesGatewayBaseUrl: 'https://services.unity.com/api/',
  },
};

const getEnvConfig = () => {
  const domainSpecificConfig = configsByOrigin[window.location.hostname];
  const defaultConfig = configsByOrigin[LOCAL_DOMAIN];
  return domainSpecificConfig || defaultConfig;
};

export default getEnvConfig;
