import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './organization-api';


export const storeCollection = 'organizations';
export const actionTypes = getActionTypesFor('ORGANIZATIONS');
export const actionCreators = getActionCreatorsFor('ORGANIZATIONS');
export const apiActionCreators = getApiActions(actionCreators);

const getOneOrganization = (coreOrganizationId) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');

  return apiActionCreators.getOne({
    forceFetch: true,
    $id: coreOrganizationId,
    collection: storeCollection,
    request: () => api.getOne(coreOrganizationId),
  });
};

const updateOrganization = (coreOrganizationId, organization) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');
  if (!organization) throw new Error('Missing organization payload');

  return apiActionCreators.update({
    request: () => api.update(coreOrganizationId, organization),
    $id: coreOrganizationId,
  });
};

const getAllOrganizations = () => apiActionCreators.getAll({
  forceFetch: true,
  collection: storeCollection,
  request: () => api.getAll(),
});

const getAllUnityOrganizations = () => apiActionCreators.getAll({
  forceFetch: true,
  collection: storeCollection,
  request: () => api.getAllUnityOrganizations(),
});

const actions = {
  getAllOrganizations,
  getAllUnityOrganizations,
  getOneOrganization,
  updateOrganization,
};

export default actions;
