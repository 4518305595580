import { combineReducers } from 'redux';
import users from './entities/user/user-reducer';
import adminUsers from './entities/admin-user/admin-user-reducer';
import organizations from './entities/organization/organization-reducer';
import organizationUsers from './entities/organization-user/organization-user-reducer';
import organizationBidders from './entities/organization-bidder/organization-bidder-reducer';
import bidderSegments from './entities/traffic-segment/traffic-segment-reducer';
import bidders from './entities/bidder/bidder-reducer';
import uiImpersonator from './ui/impersonator/impersonator-reducer';
import uiSampleBidRequestConfig from './ui/sample-bid-request/sample-bid-request-reducer';
import uiBidResponseAndLog from './ui/bid-response-validator/bid-response-validator-reducer';
import uiAdminBar from './ui/admin-bar/admin-bar-reducer';
import adminUserPage from './ui/admin/user-page-reducer';
import sideBar from './ui/side-bar/side-bar-reducer';
import intercom from './ui/intercom/intercom-reducer.js';


export default combineReducers({
  users,
  adminUsers,
  organizations,
  organizationUsers,
  bidders,
  organizationBidders,
  bidderSegments,
  uiImpersonator,
  uiSampleBidRequestConfig,
  uiBidResponseAndLog,
  uiAdminBar,
  adminUserPage,
  sideBar,
  intercom,
});
