import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@unity/react-components/typography';
import StateIndicator from '@unity/react-components/state-indicator';
import ContentRow from '@unity/react-components/content-row';
import EntitySelectionButton from '@unity/react-labs/entity-selection-button';
import { makeStyles } from '@material-ui/core/styles';
import createSearcher from 'src/lib/search/search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import SearchBase from '@unity/react-labs/search-base';
import NoResults from '@unity/react-labs/search-base/no-results';
import DarkThemeProvider from '@unity/react-labs/app-header/dark-theme-provider';
import sideBarActions from 'src/store/ui/side-bar/side-bar-actions';
import { createLinkFor } from 'src/lib/create-link-for/create-link-for';
import { Button } from '@unity/react-components/button/button';


const useStyles = makeStyles(({ spacing, palette }) => ({
  bidderButtonLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  paperProps: {
    width: spacing(31.25),
    left: '0 !important',
  },
  listItem: {
    height: spacing(7),
    display: 'flex',
  },
  selectedListItem: {
    height: spacing(7),
    display: 'flex',
    backgroundColor: palette.grey[200],
  },
  selectedListItemButton: {
    color: palette.primary.main,
  },
  bidderName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  bidderNameList: {
    maxWidth: spacing(22),
  },
  bidderNameHeader: {
    maxWidth: spacing(20),
  },
  listItemButton: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
  },
}));

export const messages = defineMessages({
  searchPlaceholder: {
    id: 'organizationMenuIntegration.bidderSwitcher.searchPlaceholder',
    defaultMessage: 'Search for bidder',
  },
  title: {
    id: 'organizationMenuIntegration.bidderSwitcher.title',
    defaultMessage: 'Bidder',
  },
});

const bidderSearcher = createSearcher({ searchKeys: ['bidderName'] });

const renderBidderRow = (classes, className, bidder, size) => {
  const state = bidder.isEnabled ? 'success' : 'disabled';
  return (
    <ContentRow spacing={1} inline className={classes.listItemButton}>
      <StateIndicator state={state} size={size} />
      <div className={className}> {bidder.bidderName} </div>
    </ContentRow>
  );
};

export const BidderSwitcher = ({ currentBidder, bidders, coreOrganizationId, actions }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  let selectedBidder;
  if (currentBidder) {
    selectedBidder = bidders.find((bidder) => currentBidder.bidderID === bidder.bidderID);
  }

  selectedBidder = selectedBidder || bidders[0];

  React.useEffect(() => {
    actions.updateCurrentBidder(selectedBidder);
  }, [coreOrganizationId]);

  const handleBidderChange = (bidder) => {
    setSearchValue('');
    setAnchorEl(null);
    actions.updateCurrentBidder(bidder);

    // we should redirect to the new bidder configuration page if we are currently on the old one
    const selectedBidderConfigurationPage = createLinkFor(`/bidders/${selectedBidder.bidderID}/configuration`, { coreOrganizationId });
    const currentPath = window.location.pathname;
    if (currentPath === selectedBidderConfigurationPage
      && bidder.bidderID !== selectedBidder.bidderID
    ) {
      const newUrl = createLinkFor(`/bidders/${bidder.bidderID}/configuration`, { coreOrganizationId });
      history.push(newUrl);
    }
  };

  const handleChange = (event) => {
    setSearchValue(event);
  };

  const handleSearch = (searchVal) => bidderSearcher(bidders, searchVal);

  const RenderList = ({ hasNoResults, results }) => {
    if (hasNoResults && searchValue) {
      return <NoResults>No search results</NoResults>;
    }

    // Show option list by default (when not searching)
    const collection = searchValue ? results : bidders;
    return (
      <List disablePadding>
        {collection.map((bidder) => (
          <ListItem
            divider
            key={bidder.bidderID}
            className={
              selectedBidder.bidderID === bidder.bidderID
                ? classes.selectedListItem
                : classes.listItem
            }
          >
            <Button
              onClick={() => handleBidderChange(bidder)}
              className={classes.listItemButton}
              ButtonProps={{
                className: selectedBidder.bidderID === bidder.bidderID
                  ? classes.selectedListItemButton
                  : classes.listItemButton,
              }}
            >
              {renderBidderRow(classes, `${classes.bidderNameList} ${classes.bidderName}`, bidder, 10)}
            </Button>
          </ListItem>
        ))}
      </List>
    );
  };

  RenderList.propTypes = {
    hasNoResults: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  const BidderDropDown = () => (
    <Paper elevation={0}>
      <SearchBase
        value={searchValue}
        onChange={handleChange}
        onSearch={handleSearch}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        // SearchInputProps={{ classes: { root: classes.searchBase } }}
      >
        {RenderList}
      </SearchBase>
    </Paper>
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <DarkThemeProvider>
        <EntitySelectionButton
          onClick={handleClick}
          classes={{ label: classes.bidderButtonLabel, button: classes.bidderButton }}
        >
          <Typography variant="caption" component="span">
            {intl.formatMessage(messages.title)}
          </Typography>
          <Typography variant="titleBig" component="span">
            {renderBidderRow(classes, `${classes.bidderNameHeader} ${classes.bidderName}`, selectedBidder, 12)}
          </Typography>
        </EntitySelectionButton>
      </DarkThemeProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ className: classes.paperProps }}
      >
        {BidderDropDown()}
      </Popover>
    </>
  );
};

BidderSwitcher.propTypes = {
  actions: PropTypes.shape({
    updateCurrentBidder: PropTypes.func,
  }).isRequired,
  bidders: PropTypes.array.isRequired,
  coreOrganizationId: PropTypes.string.isRequired,
  currentBidder: PropTypes.shape({
    bidderID: PropTypes.string.isRequired,
  }),
};

BidderSwitcher.defaultProps = {
  currentBidder: undefined,
};

const mapStateToProps = (state) => ({
  currentBidder: state.sideBar.currentBidder,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateCurrentBidder: sideBarActions.actionCreators.updateCurrentBidder,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(BidderSwitcher);
