import { actionTypes, messages } from './bid-response-validator-actions';


export const getInitialState = () => ({
  bidResponse: '',
  validationResult: [],
  errorLog: '',
  qrCodeValue: '',
  pasted: true,
  sdkLog: [],
});

const handleUpdateBidResponse = (prevState, bidResponse) => ({
  ...prevState,
  bidResponse,
});

const handleUpdateValidationResult = (prevState, validationResult, updatedLog) => ({
  ...prevState,
  errorLog: updatedLog,
  sdkLog: [],
  validationResult,
});

const handleUpdateErrorLog = (prevState, errorLog) => ({
  ...prevState,
  validationResult: [],
  sdkLog: [],
  errorLog,
});

const handleUpdateQRCodeValue = (prevState, qrCodeValue) => ({
  ...prevState,
  qrCodeValue,
});

const handleToggleValidatorSetting = (prevState) => ({
  ...prevState,
  pasted: !prevState.pasted,
});

const handleUpdateSDKLog = (prevState, newLog) => ({
  ...prevState,
  errorLog: '',
  validationResult: [],
  sdkLog: prevState.sdkLog.concat(newLog),
});

const handleClearSDKLog = (prevState, sdkLog) => ({
  ...prevState,
  sdkLog,
});

export const reducers = {
  handleUpdateBidResponse,
  handleUpdateErrorLog,
  handleUpdateValidationResult,
  handleUpdateQRCodeValue,
  handleToggleValidatorSetting,
  handleUpdateSDKLog,
  handleClearSDKLog,
};

const bidResponseAndLogReducer = (state = getInitialState(), action) => {
  const { type, bidResponse, errorLog, validationResult,
    qrCodeValue, newLog, sdkLog } = action;
  switch (type) {
    case actionTypes.updateBidResponse:
      return reducers.handleUpdateBidResponse(state, bidResponse);
    case actionTypes.updateErrorLog:
      return reducers.handleUpdateErrorLog(state, errorLog);
    case actionTypes.updateValidationResult:
    {
      const errorResults = validationResult.filter((obj) => obj.type === 'error');
      const updatedLog = errorResults.length === 0 ? messages.validInfo.defaultMessage : '';
      return reducers.handleUpdateValidationResult(state, validationResult, updatedLog);
    }
    case actionTypes.updateQRCodeValue:
      return reducers.handleUpdateQRCodeValue(state, qrCodeValue);
    case actionTypes.toggleValidatorSetting:
      return reducers.handleToggleValidatorSetting(state);
    case actionTypes.updateSDKLog:
      return reducers.handleUpdateSDKLog(state, newLog);
    case actionTypes.clearSDKLog:
      return reducers.handleClearSDKLog(state, sdkLog);
    default:
      return state;
  }
};

export default bidResponseAndLogReducer;
