import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fullHeight: {
    height: '100vh',
  },
};

export const CenteredContent = (props) => {
  const { classes, children, className, component: Component, fullHeight, ...rest } = props;
  const blockClassName = classNames(className, classes.root, {
    [classes.fullHeight]: fullHeight,
  });
  return (
    <Component {...rest} className={blockClassName}>
      {children}
    </Component>
  );
};

CenteredContent.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.string,
  fullHeight: PropTypes.bool,
};

CenteredContent.defaultProps = {
  className: undefined,
  component: 'div',
  fullHeight: false,
};

export default withStyles(styles, { name: 'CenteredContent' })(CenteredContent);
