import { get } from 'lodash';
import { CURRENT_USER_ID } from 'src/constants/app-constants';
import organizationSelectors from 'src/store/entities/organization/organization-selectors';
import { hasAdminRole } from 'src/components/access/access';


const getCurrentUser = (state) => state.users.entities[CURRENT_USER_ID] || {};

const getSelectedOrganizationCoreId = (state) => {
  const user = getCurrentUser(state);

  // Check whether user data has arrived in the store
  if (!user.organizations) {
    return null;
  }

  const coreOrganizationId = user.selectedOrganization;

  // When user was removed from organization which was his/her selectedOrganization
  if (!coreOrganizationId) {
    return user.organizations[0].coreOrganization;
  }

  return coreOrganizationId;
};

const getUserById = (state, userId) => state.users.entities[userId] || {};

const getCurrentUserFeatureAccess = (state, coreOrganizationId, featureName) => {
  const user = getCurrentUser(state);
  const organization = organizationSelectors.getOrganizationByCoreId(state, coreOrganizationId);
  const userRoles = get(user, '_roles', []);
  const featureToggles = get(organization, 'featureToggles', {});
  const orgHasBidderAccess = featureToggles[featureName];
  return orgHasBidderAccess || hasAdminRole(userRoles);
};

const selectors = {
  getCurrentUser,
  getSelectedOrganizationCoreId,
  getUserById,
  getCurrentUserFeatureAccess,
};

export default selectors;
