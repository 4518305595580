const getBiddersForOrganization = (state, coreOrganizationId) => {
  const bidderEntities = Object.values(state.organizationBidders.entities).filter(
    (bidder) => (bidder.coreOrganizationId === coreOrganizationId),
  );

  return bidderEntities;
};

const getBidderEmails = (state) => {
  const emails = [];
  const bidders = Object.values(state.organizationBidders.entities);
  bidders.forEach((bidder) => { emails.push(bidder.endpoint); });

  return emails;
};

export const selectors = {
  getBiddersForOrganization,
  getBidderEmails,
};

export default selectors;
