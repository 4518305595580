/* eslint-disable react/no-array-index-key */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Typography, Divider } from '@material-ui/core';
import { defineMessages, injectIntl } from 'react-intl';
import Sidebar from '@unity/react-components/sidebar/sidebar';
import SidebarItemExpandable from '@unity/react-components/sidebar/sidebar-item-expandable';
import SidebarList from '@unity/react-components/sidebar/sidebar-list';
import DarkThemeProvider from '@unity/react-labs/app-header/dark-theme-provider';
import SidebarLink from 'src/components/sidebar-link/sidebar-link';
import Access from 'src/components/access/access';
import { ORGANIZATION_FEATURES } from 'src/constants/app-constants';
import BidderSwitcher from 'src/app/organization-menu-integration/components/bidder-switcher/bidder-switcher.js';


const styles = ({ constants, transitions, spacing }) => ({
  sidebar: {
    backgroundColor: '#1F2A33',
    transition: `padding-top ${transitions.duration.short}ms`,
  },
  sidebarWithAdminBar: {
    paddingTop: constants.headerHeight + constants.adminHeaderHeight,
  },
  sidebarHeader: {
    paddingLeft: spacing(3),
    paddingBottom: spacing(4),
    textAlign: 'left',
  },
  divider: {
    backgroundColor: '#485260',
  },
});

export const messages = defineMessages({
  nonAdmin: {
    id: 'sideNavigation.sideNavMenu.header.nonAdmin',
    defaultMessage: 'Partner Portal',
  },
  admin: {
    id: 'sideNavigation.sideNavMenu.header.admin',
    defaultMessage: 'Partner Portal (Admin)',
  },
});

export class SidenavMenu extends React.Component {
  componentDidUpdate(prevProps) {
    const previousPath = prevProps.location.pathname;
    const currentPath = this.props.location.pathname;
    const wasSideMenuItemClicked = currentPath !== previousPath;

    if (wasSideMenuItemClicked) {
      this.props.onClose();
    }
  }

  renderHeader() {
    const { isAdminPage, intl, classes } = this.props;
    const headerStr = intl.formatMessage(isAdminPage ? messages.admin : messages.nonAdmin);

    return (
      <div>
        <Typography id="sidebar-header-text" variant="h4" color="textPrimary" align="center" className={classes.sidebarHeader}>{headerStr}</Typography>
        <Divider className={classes.divider} />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderItems(menuItems) {
    return (
      <DarkThemeProvider>
        <SidebarList>
          {menuItems.map(({ icon, path = '', text, menu, roles, features, matchPath, openNewTab }, index) => {
            if (menu) {
              return (
                <SidebarItemExpandable key={path + index} icon={icon} text={text}>
                  {menu.map((item, subIndex) => {
                    const key = item.path + subIndex;
                    const linkEl = (
                      <SidebarLink
                        padded
                        key={key}
                        to={item.path}
                        text={item.text}
                        matchPath={matchPath}
                        openNewTab={openNewTab}
                      />
                    );

                    if (item.roles || item.features) {
                      return (
                        <Access key={`user${key}`} roles={item.roles} features={item.features}>
                          {linkEl}
                        </Access>
                      );
                    }

                    return linkEl;
                  })}
                </SidebarItemExpandable>
              );
            }

            const linkEl = (
              <SidebarLink
                key={path + index}
                icon={icon}
                to={path}
                text={text}
                matchPath={matchPath}
                openNewTab={openNewTab}
              />
            );

            if (roles || features) {
              return (
                <Access key={path + index} roles={roles} features={features}>
                  {linkEl}
                </Access>
              );
            }
            return linkEl;
          })}
        </SidebarList>
      </DarkThemeProvider>
    );
  }

  render() {
    const {
      classes,
      children,
      open,
      onClose,
      isAdminBarOpen,
      menuItems,
      bidderItems,
      bidders,
      match,
    } = this.props;

    const sidebarClasses = classNames(classes.sidebar, {
      [classes.sidebarWithAdminBar]: isAdminBarOpen,
    });

    const sidebarEl = (
      <Sidebar classes={{ root: sidebarClasses }}>
        {/* {this.renderHeader()} // Re-enable after integrating with Unified Dashboard */}
        {bidderItems.length > 0 && (
          <Access features={[ORGANIZATION_FEATURES.BIDDERCONFIG]}>
            {bidders.length > 0 && (
              <BidderSwitcher
                bidders={bidders}
                coreOrganizationId={match.params.coreOrganizationId}
              />
            )}
            {this.renderItems(bidderItems)}
            <Divider className={classes.divider} />
          </Access>
        )}
        {children}
        {this.renderItems(menuItems)}
      </Sidebar>
    );

    return (
      <>
        <Drawer anchor="left" open={open} onClose={onClose} ModalProps={{ keepMounted: true }}>
          {sidebarEl}
        </Drawer>
        <Hidden smDown implementation="css">
          {sidebarEl}
        </Hidden>
      </>
    );
  }
}

SidenavMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isAdminBarOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  menuItems: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bidderItems: PropTypes.array,
  bidders: PropTypes.array,
  children: PropTypes.node,
  isAdminPage: PropTypes.bool,
};
SidenavMenu.defaultProps = {
  bidderItems: [],
  bidders: [],
  children: '',
  isAdminPage: false,
};

const mapStateToProps = (state) => ({
  isAdminBarOpen: state.uiAdminBar.isOpen,
});

export default compose(
  injectIntl,
  withStyles(styles, { name: 'SidenavMenu' }),
  connect(mapStateToProps),
  withRouter,
)(SidenavMenu);
