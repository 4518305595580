import { defineMessages } from 'react-intl';
import axios from 'src/lib/axios/gateway-axios';


export const actionTypes = {
  updateBidResponse: 'UPDATE_BID_RESPONSE',
  updateErrorLog: 'UPDATE_ERROR_LOG',
  updateValidationResult: 'UPDATE_VALIDATION_RESULT',
  updateQRCodeValue: 'UPDATE_QR_CODE_VALUE',
  toggleValidatorSetting: 'TOGGLE_VALIDATOR_SETTING',
  updateSDKLog: 'UPDATE_SDK_LOG',
  clearSDKLog: 'CLEAR_SDK_LOG',
};

export const actionCreators = {
  updateBidResponse: (bidResponse) => ({
    type: actionTypes.updateBidResponse,
    bidResponse,
  }),
  updateErrorLog: (errorLog) => ({
    type: actionTypes.updateErrorLog,
    errorLog,
  }),
  updateValidationResult: (validationResult) => ({
    type: actionTypes.updateValidationResult,
    validationResult,
  }),
  updateQRCodeValue: (qrCodeValue) => ({
    type: actionTypes.updateQRCodeValue,
    qrCodeValue,
  }),
  toggleValidatorSetting: () => ({
    type: actionTypes.toggleValidatorSetting,
  }),
  updateSDKLog: (newLog) => ({
    type: actionTypes.updateSDKLog,
    newLog,
  }),
  clearSDKLog: (sdkLog) => ({
    type: actionTypes.clearSDKLog,
    sdkLog,
  }),
};

export const messages = defineMessages({
  validInfo: {
    id: 'integrationTools.bidResponseValidator.info',
    defaultMessage: 'Bid response valid',
  },
  loading: {
    id: 'integrationTools.bidResponseValidator.loading',
    defaultMessage: 'Bid response loading...',
  },
  endpointFormat: {
    id: 'integrationTools.bidResponseValidator.endpoint',
    defaultMessage: 'Endpoint URL has to start with https://',
  },
  noBidInfo: {
    id: 'integrationTools.bidResponseValidator.noBid',
    defaultMessage: 'No bid',
  },
  endpointSelect: {
    id: 'integrationTools.bidResponseValidator.endpointSelect',
    defaultMessage: 'Please select an endpoint URL from the dropdown menu',
  },
});

export const sendRequestToEndpoint = (orgId, endpoint, sampleBidRequest) => async (dispatch) => {
  if (endpoint === '') {
    dispatch(actionCreators.updateBidResponse(messages.endpointSelect.defaultMessage));
    return;
  }
  if (!endpoint.startsWith('https://')) {
    dispatch(actionCreators.updateBidResponse(messages.endpointFormat.defaultMessage));
    return;
  }
  const encodedEndpoint = encodeURIComponent(endpoint);
  dispatch(actionCreators.updateBidResponse(messages.loading.defaultMessage));
  try {
    const { data } = await axios.post(`/monetize/partner/v1/organizations/${orgId}/bid-requests?url=${encodedEndpoint}`, sampleBidRequest);
    if (data === '') {
      dispatch(actionCreators.updateBidResponse(messages.noBidInfo.defaultMessage));
    } else {
      dispatch(actionCreators.updateBidResponse(JSON.stringify(data, null, 2)));
    }
  } catch (err) {
    dispatch(actionCreators.updateBidResponse(JSON.stringify(err, null, 2)));
  }
};

export const getValidationResult = (checkedResponse) => async (dispatch) => {
  try {
    const { data } = await axios.post('/monetize/partner/v1/bid-response-validation', checkedResponse);
    if (data.total === 0) {
      dispatch(actionCreators.updateErrorLog(messages.validInfo.defaultMessage));
    } else {
      dispatch(actionCreators.updateValidationResult(data.results));
    }
  } catch (err) {
    dispatch(actionCreators.updateErrorLog(err.response.status + err.response.statusText));
  }
};

export const getGameConfigIDs = (bidResponse, orgId, crType) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/monetize/partner/v1/organizations/${orgId}/bid-response-test?crtype=${crType}`, JSON.parse(bidResponse));
    if (data.sessionID !== '') {
      dispatch(actionCreators.updateQRCodeValue(JSON.stringify({
        sessionId: data.sessionID,
        gameId: data.gameID,
        placementId: data.placementID,
      })));
    } else if (typeof data.errors === 'string') {
      dispatch(actionCreators.updateErrorLog(data.errors));
    } else {
      dispatch(actionCreators.updateValidationResult(data.errors));
    }
  } catch (err) {
    dispatch(actionCreators.updateErrorLog(err.response.status + err.response.statusText));
  }
};
