import debounce from 'lodash/debounce';


export const GROUPS = {
  ORGANIZATION: 'Organization',
};

export const USER_PROPERTIES = {
  ROLE: 'role',
  EMAIL: 'email',
  LAST_SEEN: 'lastSeen',
  FIRST_SEEN: 'firstSeen',
  ORGANIZATIONS: 'organizations',
  NUMBER_OF_SESSIONS: 'numberOfSessions',
  ORGANIZATION_NAMES: 'organizationNames',
};

const logEvent = (eventName) => debounce((eventProperties) => {
  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(eventName, eventProperties);
  }
}, 250);

export const amplitudeEvents = {
  pageView: logEvent('PAGE_VIEW'),
  pageSpeed: logEvent('PAGE_SPEED'),
  newSession: logEvent('NEW_SESSION'),

  // Bid Response Validator
  integrationPageView: logEvent('INTEGRATION_PAGE_VIEW'),
  integrationTestAppLinkClick: logEvent('INTEGRATION_TEST_APP_LINK_CLICK'),
  integrationBidResponseParseError: logEvent('INTEGRATION_BID_RESPONSE_PARSE_ERROR'),
  integrationBidResponseValidateClick: logEvent('INTEGRATION_BID_RESPONSE_VALIDATE_CLICK'),
  integrationBidResponseValidateSuccess: logEvent('INTEGRATION_BID_RESPONSE_VALIDATE_SUCCESS'),
  integrationBidResponseValidateFailure: logEvent('INTEGRATION_BID_RESPONSE_VALIDATE_FAILURE'),

  // QR Code
  integrationQrCodeCreated: logEvent('INTEGRATION_QR_CODE_CREATED'),
  integrationQrCodeScan: logEvent('INGRATION_QR_CODE_SCAN'),

  // Organization Settings
  organizationSettingsPageView: logEvent('ORGANIZATION_SETTINGS_PAGE_VIEW'),
  organizationSettingsDspIDSet: logEvent('ORGANIZATION_SETTINGS_DSP_ID_SET'),
  organizationSettingsEndpointSet: logEvent('ORGANIZATION_SETTINGS_ENDPOINT_SET'),

  // Error
  pageError: logEvent('PAGE_ERROR'),
  chunkError: logEvent('CHUNK_ERROR'),
};

export default window.amplitude;
