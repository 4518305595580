import get from 'lodash/get';


const getOrganizations = (state) => (
  state.organizations.entities
);

const getOrganizationByCoreId = (state, coreOrganizationId) => (
  state.organizations.entities[coreOrganizationId]
);

const getCurrentOrganization = (state, ownProps) => {
  const coreOrganizationId = get(ownProps, 'match.params.coreOrganizationId');
  if (!coreOrganizationId) throw new Error('Missing coreOrganizationId!');
  return getOrganizationByCoreId(state, coreOrganizationId);
};

const getCurrentOrganizationId = (state, ownProps) => {
  const { coreOrganization } = getCurrentOrganization(state, ownProps) || {};
  return coreOrganization;
};

export const selectors = {
  getOrganizations,
  getOrganizationByCoreId,
  getCurrentOrganization,
  getCurrentOrganizationId,
};

export default selectors;
