import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './organization-user-api';


export const actionTypes = getActionTypesFor('ORGANIZATION_USERS');
export const actionCreators = getActionCreatorsFor('ORGANIZATION_USERS');
export const apiActionCreators = getApiActions(actionCreators);

const getUsersForOrganization = (coreOrganizationId) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');

  return apiActionCreators.getAll({
    request: () => api.getAll(coreOrganizationId),
  });
};

const actions = {
  getUsersForOrganization,
};

export default actions;
