import { UPDATE_CURRENT_BIDDER } from './side-bar-actions';


export const getInitialState = () => ({
  currentBidder: undefined,
});

const bidderSwitcherReducer = (state = getInitialState(), { type, bidder }) => {
  switch (type) {
    case UPDATE_CURRENT_BIDDER:
      return { ...state, currentBidder: bidder };
    default:
      return state;
  }
};

export default bidderSwitcherReducer;
