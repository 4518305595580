import axios from 'src/lib/axios/gateway-axios';


const addId = (segments, coreOrganizationId, bidderId) => {
  const segs = {};
  segments.forEach((segment) => { segs[segment._id] = segment; });

  return {
    $id: coreOrganizationId,
    bidders: {
      [bidderId]: {
        segments: segs,
      },
    },
  };
};

const deleteOne = async (coreOrganizationId, bidderID, segmentId) => {
  const { data } = await axios.delete(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${bidderID}/segments/${segmentId}`);
  return data;
};

const getAll = async (coreOrganizationId, bidderId) => {
  let { data } = await axios.get(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${bidderId}/segments`);
  data = addId(data, coreOrganizationId, bidderId);
  return [data];
};

const update = async (coreOrganizationId, bidderId, segmentId, segment) => {
  const { data } = await axios.put(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${bidderId}/segments/${segmentId}`, segment);
  return data;
};

const create = async (coreOrganizationId, bidderId, segment) => {
  const { data } = await axios.post(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${bidderId}/segment`, segment);
  return data;
};

export default {
  deleteOne,
  getAll,
  update,
  create,
};
