import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const styles = () => ({
  item: {
    textAlign: 'center',
  },
});

export const Preloader = ({ classes, ...rest }) => (
  <div className={classes.item}>
    <CircularProgress {...rest} />
  </div>
);

Preloader.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number,
};

Preloader.defaultProps = {
  size: 40,
};

export default withStyles(styles, { name: 'Preloader' })(Preloader);
