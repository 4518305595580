import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import get from 'lodash/get';
import userSelectors from 'src/store/entities/user/user-selectors';
import userActions from 'src/store/entities/user/user-actions';
import { CURRENT_USER_ID } from 'src/constants/app-constants';
import CenteredContent from 'src/components/centered-content/centered-content';
import Preloader from 'src/components/preloader/preloader';
import FetchError from 'src/components/errors/fetch-error';


export class RootRedirect extends React.Component {
  componentDidMount() {
    this.props.actions.getOneUser(CURRENT_USER_ID);
  }

  render() {
    const { coreOrganizationId, isFetching, fetchError } = this.props;

    if (isFetching) {
      return (
        <CenteredContent fullHeight>
          <Preloader size={150} />
        </CenteredContent>
      );
    }

    if (fetchError) {
      return <FetchError />;
    }

    const userHomeRoute = `/organizations/${coreOrganizationId}`;

    return (
      <Redirect to={userHomeRoute} />
    );
  }
}

RootRedirect.propTypes = {
  actions: PropTypes.shape({
    getOneUser: PropTypes.func,
  }).isRequired,
  coreOrganizationId: PropTypes.string.isRequired,
  fetchError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const user = userSelectors.getCurrentUser(state);
  const isFetching = get(user, 'metadata.isFetching', true);
  const fetchError = get(user, 'metadata.fetchError', false);
  const coreOrganizationId = userSelectors.getSelectedOrganizationCoreId(state) || '';
  return { coreOrganizationId, isFetching, fetchError };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getOneUser: userActions.getUser,
  }, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(RootRedirect);
