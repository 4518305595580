import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './traffic-segment-api';
import { ERR_REQUEST_INVALID, ERR_ID_INVALID } from '../../utils/actions';


export const storeCollection = 'segments';
const trafficActionTypes = getActionTypesFor('TRAFFIC_SEGMENTS');
const trafficActionCreators = getActionCreatorsFor('TRAFFIC_SEGMENTS');
export const ERR_BIDDER_ID_INVALID = "The 'bidderId' parameter must be a valid string";
export const ERR_CORE_ORGANIZATION_ID_INVALID = "The 'coreOrganizationId' parameter must be a valid string";

export const actionTypes = {
  updateSegmentSuccess: 'TRAFFIC_SEGMENTS_UPDATE_SEGMENT_SUCCESS',
  createSegmentSuccess: 'TRAFFIC_SEGMENTS_CREATE_SEGMENT_SUCCESS',
  deleteSegmentSuccess: 'TRAFFIC_SEGMENTS_DELETE_SEGMENT_SUCCESS',
  ...trafficActionTypes,
};

export const actionCreators = {
  updateSegmentSuccess: (data) => ({
    type: actionTypes.updateSegmentSuccess,
    data,
  }),
  createSegmentSuccess: (data) => ({
    type: actionTypes.createSegmentSuccess,
    data,
  }),
  deleteSegmentSuccess: (data) => ({
    type: actionTypes.deleteSegmentSuccess,
    data,
  }),
  ...trafficActionCreators,
};

export const apiActionCreators = getApiActions(actionCreators);

const deleteSegmentAction = (
  {
    request,
    $id,
    bidderId,
    coreOrganizationId,
  },
) => async (dispatch) => {
  if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);
  if (!$id || !isString($id)) throw new Error(ERR_ID_INVALID);
  if (!bidderId || !isString(bidderId)) throw new Error(ERR_BIDDER_ID_INVALID);
  if (!coreOrganizationId
    || !isString(coreOrganizationId)
  ) throw new Error(ERR_CORE_ORGANIZATION_ID_INVALID);

  try {
    dispatch(actionCreators.deletePending(coreOrganizationId));
    const result = await request();
    dispatch(actionCreators.deleteSegmentSuccess({
      $id,
      bidderId,
      coreOrganizationId,
    }));
    return result;
  } catch (err) {
    dispatch(actionCreators.deleteError({ $id: coreOrganizationId, error: err }));
    throw err;
  }
};

const updateSegmentAction = (
  {
    request,
    $id,
    bidderId,
    coreOrganizationId,
  },
) => async (dispatch) => {
  if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);
  if (!$id || !isString($id)) throw new Error(ERR_ID_INVALID);
  if (!bidderId || !isString(bidderId)) throw new Error(ERR_BIDDER_ID_INVALID);
  if (!coreOrganizationId
    || !isString(coreOrganizationId)
  ) throw new Error(ERR_CORE_ORGANIZATION_ID_INVALID);

  try {
    dispatch(actionCreators.updatePending({ $id: coreOrganizationId }));
    const data = await request();
    dispatch(actionCreators.updateSegmentSuccess({
      $id,
      bidderId,
      coreOrganizationId,
      data,
    }));
    return data;
  } catch (err) {
    dispatch(actionCreators.deleteError({ $id: coreOrganizationId, error: err }));
    throw err;
  }
};

const createSegmentAction = (
  {
    request,
    bidderId,
    coreOrganizationId,
  },
) => async (dispatch) => {
  if (!request || !isFunction(request)) throw new Error(ERR_REQUEST_INVALID);
  if (!bidderId || !isString(bidderId)) throw new Error(ERR_BIDDER_ID_INVALID);
  if (!coreOrganizationId
    || !isString(coreOrganizationId)
  ) throw new Error(ERR_CORE_ORGANIZATION_ID_INVALID);

  try {
    dispatch(actionCreators.createPending());
    const data = await request();
    dispatch(actionCreators.createSegmentSuccess({
      $id: data._id,
      bidderId,
      coreOrganizationId,
      data,
    }));
    return data;
  } catch (err) {
    dispatch(actionCreators.createError({ error: err }));
    throw err;
  }
};

const deleteSegment = (coreOrganizationId, bidderId, segmentId) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');
  if (!bidderId) throw new Error('Invalid bidderId');
  if (!segmentId) throw new Error('Invalid segmentId');

  return deleteSegmentAction({
    $id: segmentId,
    bidderId,
    coreOrganizationId,
    request: () => api.deleteOne(coreOrganizationId, bidderId, segmentId),
  });
};

const getSegmentsForBidder = (coreOrganizationId, bidderId) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');
  if (!bidderId) throw new Error('Invalid bidderId');

  return apiActionCreators.getAll({
    request: () => api.getAll(coreOrganizationId, bidderId),
  });
};

const updateSegmentById = (coreOrganizationId, bidderId, id, segment) => {
  if (!id) throw new Error('Missing segmentId');
  if (!bidderId) throw new Error('Missing bidderId');
  if (!coreOrganizationId) throw new Error('Missing coreOrganizationId');
  const { segmentId, ...segmentToSend } = segment;

  return updateSegmentAction({
    request: () => api.update(coreOrganizationId, bidderId, id, segmentToSend),
    $id: id,
    coreOrganizationId,
    bidderId,
  });
};

const createSegment = (coreOrganizationId, bidderId, segment) => {
  if (!bidderId) throw new Error('Missing bidderId');
  if (!coreOrganizationId) throw new Error('Missing coreOrganizationId');
  const { segmentId, ...segmentToSend } = segment;

  return createSegmentAction({
    request: () => api.create(coreOrganizationId, bidderId, segmentToSend),
    coreOrganizationId,
    bidderId,
  });
};

const actions = {
  deleteSegment,
  getSegmentsForBidder,
  updateSegmentById,
  createSegment,
};

export default actions;
