import RouteParser from 'route-parser';


export const createPathFor = (urlSchema, params) => new RouteParser(urlSchema).reverse(params);

export const getRouteByPathname = (routes, urlPath) => {
  let params = null;
  const route = Object.values(routes).find(({ path }) => {
    const routeMatch = new RouteParser(path);
    params = routeMatch.match(urlPath);
    return params;
  });
  return { params, route };
};
