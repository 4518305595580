import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = ({ constants, breakpoints, spacing }) => ({
  container: {
    width: '100%',
    height: '100vh',
    paddingTop: constants.headerHeight,
    flex: 1,

    [breakpoints.up('md')]: {
      paddingLeft: constants.sidebarWidth,
    },

    [breakpoints.up('lg')]: {
      maxWidth: constants.maxContainerWidth + constants.sidebarWidth,
      marginRight: 'auto',
    },

    [breakpoints.up('xl')]: {
      width: constants.maxContainerWidth + constants.sidebarWidth,
      marginLeft: 'auto',
    },
  },
  withAdminBar: {
    height: '100vh',
    paddingTop: constants.headerHeight + constants.adminHeaderHeight,
  },
  content: {
    padding: spacing(4),

    [breakpoints.down('sm')]: {
      padding: spacing(3),
    },
  },
  fullWidth: {
    paddingLeft: 0,
  },
});

export const LayoutWrapper = (props) => {
  const { classes, children, fullWidth, isAdminBarOpen } = props;

  const containerClasses = classNames(classes.container, {
    [classes.fullWidth]: fullWidth,
    [classes.withAdminBar]: isAdminBarOpen,
  });

  return (
    <div className={containerClasses} data-testid="container">
      <main className={classes.content} data-testid="content">
        {children}
      </main>
    </div>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  isAdminBarOpen: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
};

LayoutWrapper.defaultProps = {
  fullWidth: false,
};

const mapStateToProps = (state) => ({
  isAdminBarOpen: state.uiAdminBar.isOpen,
});

export default compose(
  withStyles(styles, { name: 'LayoutWrapper' }),
  connect(mapStateToProps),
)(LayoutWrapper);
