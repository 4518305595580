import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import StateCard from '@unity/react-components/state-card';


export const messages = defineMessages({
  accessRequired: {
    id: 'noAccessError.accessRequired',
    defaultMessage: 'No access',
  },
  noSufficientPermissions: {
    id: 'noAccessError.noSufficientPermissions',
    defaultMessage: "You don't have sufficient permissions to view this page.",
  },
  takeMeHome: {
    id: 'noAccessError.takeMeHome',
    defaultMessage: 'Take me home',
  },
});

const handleNavigationToHomePage = () => window.location.assign('/');

export const NoAccess = ({ intl, showHomeButton }) => {
  const buttonProps = {
    onClick: handleNavigationToHomePage,
    label: intl.formatMessage(messages.takeMeHome),
  };

  return (
    <Card>
      <StateCard
        elevation={0}
        title={intl.formatMessage(messages.accessRequired)}
        description={intl.formatMessage(messages.noSufficientPermissions)}
        ButtonProps={showHomeButton ? buttonProps : {}}
      />
    </Card>
  );
};

NoAccess.propTypes = {
  intl: PropTypes.object.isRequired,
  showHomeButton: PropTypes.bool,
};

NoAccess.defaultProps = {
  showHomeButton: false,
};

export default injectIntl(NoAccess);
