import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import SidenavMenu from 'src/app/side-navigation/sidenav-menu';


export const messages = defineMessages({
  organizations: {
    id: 'adminMenuIntegration.adminMenuIntegration.organizations',
    defaultMessage: 'Organizations',
  },
  users: {
    id: 'adminMenuIntegration.adminMenuIntegration.users',
    defaultMessage: 'Users',
  },
});

export const AdminMenuIntegration = (props) => {
  const { intl, open, onClose } = props;
  const menuItems = [
    {
      icon: ListIcon,
      path: '/admin/organizations',
      text: intl.formatMessage(messages.organizations),
    },
    {
      icon: PersonIcon,
      path: '/admin/users',
      text: intl.formatMessage(messages.users),
    },
  ].filter((item) => Boolean(item));

  return (
    <SidenavMenu menuItems={menuItems} open={open} onClose={onClose} isAdminPage />
  );
};

AdminMenuIntegration.propTypes = {
  intl: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
)(AdminMenuIntegration);
