import get from 'lodash/get';
import uniq from 'lodash/uniq';
import pickBy from 'lodash/pickBy';
import intersection from 'lodash/intersection';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import userSelectors from 'src/store/entities/user/user-selectors';
import organizationSelectors from 'src/store/entities/organization/organization-selectors';
import { ADMIN_ROLES } from 'src/constants/app-constants';
import { compose } from 'redux';


export const hasAccess = (userRoles, roles) => !roles || intersection(userRoles, roles).length > 0;
export const hasAdminRole = (userRoles) => (
  intersection(userRoles, Object.values(ADMIN_ROLES)).length > 0
);
export const hasFeature = (userFeatures, features) => (
  !features || intersection(userFeatures, features).length > 0
);

export const Access = (props) => {
  const { children, authorizedContent, unauthorizedContent } = props;
  const { features, roles, userFeatures, userRoles } = props;
  const isUsingConflictingAccessApi = children && (authorizedContent || unauthorizedContent);
  const userHasAccess = hasAccess(userRoles, roles);
  const userHasFeature = hasFeature(userFeatures, features) || hasAdminRole(userRoles);

  if (isUsingConflictingAccessApi) {
    throw new Error('Either define content as children or as a prop');
  }

  if (children && userHasAccess && userHasFeature) {
    return children;
  }

  return userHasAccess && userHasFeature ? authorizedContent : unauthorizedContent;
};

Access.propTypes = {
  userFeatures: PropTypes.array.isRequired,
  userRoles: PropTypes.array.isRequired,
  authorizedContent: PropTypes.node,
  children: PropTypes.node,
  features: PropTypes.array,
  roles: PropTypes.array,
  unauthorizedContent: PropTypes.node,
};

Access.defaultProps = {
  unauthorizedContent: null,
  features: null,
  roles: null,
};

const getImpersonatedRolesAndFeatures = (state) => ({
  userFeatures: Object.keys(pickBy(state.uiImpersonator.features)),
  userRoles: Object.keys(pickBy(state.uiImpersonator.roles)),
});

const getActualRolesAndFeatures = (state, ownProps) => {
  const coreOrganizationId = ownProps.match.params.coreOrganizationId
    || state.users.entities.me.selectedOrganization;
  const user = userSelectors.getCurrentUser(state, ownProps);
  const organization = organizationSelectors.getOrganizationByCoreId(state, coreOrganizationId);
  const userRoles = get(user, '_roles', []);
  const organizationRoles = get(organization, '_roles', []);
  const featureToggles = get(organization, 'featureToggles', {});

  return {
    userRoles: uniq([...userRoles, ...organizationRoles]),
    userFeatures: Object.keys(pickBy(featureToggles)),
  };
};

export const mapStateToProps = (state, ownProps) => (
  state.uiImpersonator.isImpersonating
    ? getImpersonatedRolesAndFeatures(state, ownProps)
    : getActualRolesAndFeatures(state, ownProps)
);

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Access);
