import { actionTypes } from './user-page-actions';


export const getInitialState = () => ({
  filteredDSP: '',
});

const adminUserPageReducer = (state = getInitialState(), { type, data }) => {
  switch (type) {
    case actionTypes.UPDATE_DSP_FILTER:
      return { ...state, filteredDSP: data };
    default:
      return state;
  }
};

export default adminUserPageReducer;
