import { withStyles } from '@material-ui/core/styles';


const styles = {
  '@keyframes fire': {
    '0%': {
      color: '#DC143C',
    },
    '25%': {
      color: '#ce4320',
    },
    '50%': {
      color: '#ffd700',
    },
    '75%': {
      color: '#ce4320',
    },
    '100%': {
      color: '#DC143C',
    },
  },
};

const Animations = () => null;

export default withStyles(styles, { name: 'Animations' })(Animations);
