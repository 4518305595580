import React, { Component } from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import { injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import UserMenu from '@unity/react-components/header/user-menu';
import UserMenuLoader from '@unity/react-components/header/user-menu-loader';
import HeaderContainer from '@unity/react-components/header/header-container';
import HeaderIconButton from '@unity/react-components/header/header-icon-button';
import PrimaryNavigationTabs from '@unity/react-labs/app-header/primary-navigation-tabs';
import DarkThemeProvider from '@unity/react-labs/app-header/dark-theme-provider';
import auth from 'src/lib/auth/auth';
import getEnvConfig from 'src/lib/env-config';
import userActions from 'src/store/entities/user/user-actions';
import { CURRENT_USER_ID, ADMIN_ROLES, ORGANIZATION_ROLES } from 'src/constants/app-constants';
import Access from 'src/components/access/access';
import UnityLogo from './components/logo';


export const messages = defineMessages({
  openNavigation: {
    id: 'header.openSecondaryNavigation',
    defaultMessage: 'Open site navigation',
  },
  account: {
    id: 'header.account',
    defaultMessage: 'Account',
  },
  signOut: {
    id: 'header.signOut',
    defaultMessage: 'Sign out',
  },
  switchOrganization: {
    id: 'header.switchOrganization',
    defaultMessage: 'Switch organization',
  },
  manageOrganizations: {
    id: 'header.manageOrganizations',
    defaultMessage: 'Manage organizations',
  },
  integration: {
    id: 'header.integration',
    defaultMessage: 'Integration',
  },
  documentation: {
    id: 'header.documentation',
    defaultMessage: 'Documentation',
  },
  applicationSwitcher: {
    id: 'header.applicationSwitcher',
    defaultMessage: 'Application switcher',
  },
  secondaryNavigation: {
    id: 'header.secondaryNavigation',
    defaultMessage: 'Open site navigation',
  },
  intercom: {
    id: 'header.intercom',
    defaultMessage: 'Intercom',
  },
});

const styles = ({ palette }) => ({
  secondaryNavigationToggler: {
    color: palette.text.primary,
  },
  itemsContainer: {
    height: '100%',
  },
});

export const getDropdownOrganizations = (organizations, selectedCoreOrganizationId) => (
  organizations.map(
    ({ coreOrganization: coreOrganizationId, name }) => ({
      id: coreOrganizationId,
      selected: coreOrganizationId === selectedCoreOrganizationId,
      name,
    }),
  )
);

export const signOut = () => {
  auth.logout();
};

const urls = pick(getEnvConfig(), [
  'accountManagementUrl',
  'organizationManagementUrl',
]);

export class Header extends Component {
  handleOrganizationChange = async ({ id }) => {
    await this.props.actions.updateUser(CURRENT_USER_ID, { selectedOrganization: id });
    this.props.history.push(`/organizations/${id}`);
  };

  isTabActive = (tab) => {
    const res = matchPath(window.location.pathname, {
      path: `/organizations/:id/${tab.toLowerCase()}`,
      exact: false,
      strict: false,
    });
    return res !== null;
  };

  render() {
    const {
      intl,
      classes,
      fetchError,
      isFetching,
      onMobileMenuTogglerClick,
      organizationName,
      user,
      showTabs,
    } = this.props;

    const { organizations, selectedOrganization } = user;

    const isUserLoaderVisible = !fetchError && isFetching;
    const isUserDataVisible = !fetchError && user && !isFetching;
    const navigationTabItems = [];

    if (showTabs) {
      navigationTabItems.push({
        href: '/integration',
        label: intl.formatMessage(messages.integration),
        selected: true,
      });
    }

    return (
      <DarkThemeProvider>
        <HeaderContainer>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={classes.itemsContainer}
          >
            <Access roles={[...Object.values(ORGANIZATION_ROLES), ADMIN_ROLES.ADMIN]}>
              <Hidden mdUp>
                <HeaderIconButton
                  icon={MenuIcon}
                  aria-haspopup="true"
                  onClick={onMobileMenuTogglerClick}
                  aria-label={intl.formatMessage(messages.openNavigation)}
                  classes={{ icon: classes.secondaryNavigationToggler }}
                />
              </Hidden>
            </Access>
            <Hidden smDown>
              <UnityLogo />
            </Hidden>
            <PrimaryNavigationTabs
              items={navigationTabItems}
            />
          </Grid>
          <Grid
            container
            className={classes.itemsContainer}
            alignItems="center"
            justify="flex-end"
            wrap="nowrap"
            component="nav"
          >
            {isUserDataVisible && (
              <UserMenu
                user={user}
                strings={{
                  signOut: intl.formatMessage(messages.signOut),
                  accountManage: intl.formatMessage(messages.account),
                  organizationsManage: intl.formatMessage(messages.manageOrganizations),
                  switchOrganization: intl.formatMessage(messages.switchOrganization),
                  applicationSwitcherAriaLabel: intl.formatMessage(messages.applicationSwitcher),
                  secondaryNavigationAriaLabel: intl.formatMessage(messages.secondaryNavigation),
                }}
                onSignOut={signOut}
                organizations={getDropdownOrganizations(organizations, selectedOrganization)}
                onOrganizationSelect={this.handleOrganizationChange}
                accountManagementUrl={urls.accountManagementUrl}
                visibleOrganizationName={organizationName}
                organizationManagementUrl={urls.organizationManagementUrl}
              />
            )}
            {isUserLoaderVisible && <UserMenuLoader />}
          </Grid>
        </HeaderContainer>
      </DarkThemeProvider>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.shape({
    updateUser: PropTypes.func,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  fetchError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  organizationName: PropTypes.string.isRequired,
  onMobileMenuTogglerClick: PropTypes.func.isRequired,
  showTabs: PropTypes.bool,
  user: PropTypes.object,
};

Header.defaultProps = {
  user: {
    organizations: [],
  },
  showTabs: false,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateUser: userActions.updateUser,
  }, dispatch),
});

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles, { name: 'Header' }),
  connect(null, mapDispatchToProps),
)(Header);
