import initialState from 'src/store/initial-state';
import reducers from 'src/store/utils/reducers';
import { actionTypes } from './organization-user-actions';


const organizationUserReducer = (state = initialState.organizationUsers, { type, data }) => {
  switch (type) {
    case actionTypes.getAllSuccess:
      return reducers.handleGetAllSuccess(state, data);
    case actionTypes.getAllError:
      return reducers.handleGetAllError(state);
    case actionTypes.getAllPending:
      return reducers.handleGetAllPending(state);
    default:
      return state;
  }
};

export default organizationUserReducer;
