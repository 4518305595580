import axios from 'src/lib/axios/gateway-axios';

// Add $id used in Redux store
const addUniqueId = (organization) => ({
  ...organization,
  $id: organization.coreOrganization,
});

const addGetAllFlag = (organization) => ({
  ...organization,
  includeInOrganizationTable: true,
});

const getOne = async (coreOrganizationId) => {
  const { data } = await axios.get(`/monetize/partner/v1/organizations/${coreOrganizationId}`);
  const { data: organizationContext } = await axios.patch(`/unity/legacy/v1/users/me/context/organizations/${coreOrganizationId}`);
  data._roles = organizationContext.organization.roles;
  return addUniqueId(data);
};

const update = async (coreOrganizationId, organization) => {
  const { data } = await axios.put(`/monetize/partner/v1/organizations/${coreOrganizationId}`, organization);
  const { data: organizationContext } = await axios.patch(`/unity/legacy/v1/users/me/context/organizations/${coreOrganizationId}`);
  data._roles = organizationContext.organization.roles;
  return addUniqueId(data);
};

const getAll = async () => {
  const retval = [];

  try {
    const { data } = await axios.get('/monetize/partner/v1/organizations');

    data.organizations.forEach((org) => {
      retval.push(addGetAllFlag(addUniqueId(org)));
    });
  } catch (error) {
    console.error(`Failed to getOrganizations. ${error.apiError}`);
  }

  return retval;
};

const getAllUnityOrganizations = async () => {
  const retval = [];

  try {
    const { data } = await axios.get('partners');

    data.organizations.forEach((org) => {
      retval.push(addUniqueId(org));
    });
  } catch (error) {
    console.error(`Failed to getOrganizations. ${error.apiError}`);
  }

  return retval;
};

export default {
  getAll,
  getAllUnityOrganizations,
  getOne,
  update,
};
