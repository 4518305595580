import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import TestingIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import GavelIcon from '@material-ui/icons/Gavel';
import Build from '@material-ui/icons/Build';
import Speed from '@material-ui/icons/Speed';
import SidenavMenu from 'src/app/side-navigation/sidenav-menu';
import { createLinkFor } from 'src/lib/create-link-for/create-link-for';
import { ORGANIZATION_FEATURES } from 'src/constants/app-constants';
import { connect } from 'react-redux';
import organizationBidderActions from 'src/store/entities/organization-bidder/organization-bidder-actions';
import organizationBidderSelectors from 'src/store/entities/organization-bidder/organization-bidder-selectors';
import userSelectors from 'src/store/entities/user/user-selectors';


export const messages = defineMessages({
  overview: {
    id: 'organizationMenuIntegration.overview',
    defaultMessage: 'Overview',
  },
  integrationTools: {
    id: 'organizationMenuIntegration.integrationTools',
    defaultMessage: 'Testing',
  },
  settings: {
    id: 'organizationMenuIntegration.setting',
    defaultMessage: 'Settings',
  },
  bidderConfig: {
    id: 'organizationMenuIntegration.bidderConfig',
    defaultMessage: 'Bidder configuration',
  },
  bidders: {
    id: 'organizationMenuIntegration.bidders',
    defaultMessage: 'Bidders',
  },
  segments: {
    id: 'organizationMenuIntegration.trafficSegments',
    defaultMessage: 'Traffic Segmentation',
  },
});

export const OrganizationMenuIntegration = (props) => {
  const { match, intl, open, onClose, actions, bidders, defaultBidder } = props;
  const { userHasBidderAccess } = props;
  const { coreOrganizationId: organizationId } = match.params;
  const { getBiddersForOrganization } = actions;

  const menuItems = [
    // {
    //   icon: ViewIcon,
    //   path: createLinkFor('/integration/overview', match.params),
    //   text: intl.formatMessage(messages.overview),
    //   matchPath: createLinkFor('/integration/overview', match.params),
    // },
    {
      icon: GavelIcon,
      path: createLinkFor('/bidders', match.params),
      text: intl.formatMessage(messages.bidders),
      features: [ORGANIZATION_FEATURES.BIDDERCONFIG],
    },
    {
      icon: TestingIcon,
      path: createLinkFor('/integration/tools', match.params),
      text: intl.formatMessage(messages.integrationTools),
      matchPath: createLinkFor('/integration/tools', match.params),
    },
    {
      icon: SettingsIcon,
      path: createLinkFor('/integration/settings', match.params),
      text: intl.formatMessage(messages.settings),
    },
  ].filter((item) => Boolean(item));

  React.useEffect(() => {
    if (userHasBidderAccess) {
      getBiddersForOrganization(organizationId);
    }
  }, [organizationId, userHasBidderAccess]);

  let bidderItems = [];
  if (defaultBidder) {
    bidderItems = [
      {
        icon: Build,
        path: createLinkFor(`/bidders/${defaultBidder.bidderID}/configuration`, match.params),
        text: intl.formatMessage(messages.bidderConfig),
        features: [ORGANIZATION_FEATURES.BIDDERCONFIG],
      },
      {
        icon: Speed,
        path: createLinkFor(`/bidders/${defaultBidder.bidderID}/segments`, match.params),
        text: intl.formatMessage(messages.segments),
        features: [ORGANIZATION_FEATURES.BIDDERCONFIG],
      },
    ].filter((item) => Boolean(item));
  }

  return useMemo(() => (
    <SidenavMenu
      menuItems={menuItems}
      bidderItems={bidderItems}
      bidders={bidders}
      open={open}
      onClose={onClose}
    />
  ), [organizationId, defaultBidder?.bidderID]);
};

OrganizationMenuIntegration.propTypes = {
  actions: PropTypes.shape({
    getBiddersForOrganization: PropTypes.func,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  userHasBidderAccess: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bidders: PropTypes.array,
  defaultBidder: PropTypes.object,
};

OrganizationMenuIntegration.defaultProps = {
  bidders: [],
  defaultBidder: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getBiddersForOrganization: organizationBidderActions.getBiddersForOrganization,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => {
  let bidders = [];
  let defaultBidder;
  const { coreOrganizationId } = ownProps.match.params;
  const response = organizationBidderSelectors.getBiddersForOrganization(state, coreOrganizationId);
  bidders = Object.values(response);
  if (bidders.length) {
    let { currentBidder } = state.sideBar;
    if (currentBidder) {
      currentBidder = bidders.find((bidder) => currentBidder.bidderID === bidder.bidderID);
    }
    defaultBidder = currentBidder
      || bidders.find((bidder) => bidder.isPrimary)
      || bidders[0];
  }

  const userHasBidderAccess = userSelectors.getCurrentUserFeatureAccess(
    state,
    coreOrganizationId,
    ORGANIZATION_FEATURES.BIDDERCONFIG,
  );

  return {
    userHasBidderAccess,
    bidders,
    defaultBidder,
  };
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrganizationMenuIntegration);
