import { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router';
import userSelector from 'src/store/entities/user/user-selectors.js';
import { actionCreators } from 'src/store/ui/intercom/intercom-actions';
import intercomApp from 'src/lib/intercom/intercomApp';


const Intercom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector.getCurrentUser, shallowEqual);
  const [hasBooted, setHasBooted] = useState(false);

  useEffect(() => {
    intercomApp.onUnreadCountChange((newUnreadCount) => {
      dispatch(actionCreators.setIntercomUnreadMessages(newUnreadCount));
    });

    intercomApp.onShow(() => dispatch(actionCreators.openIntercom()));
    intercomApp.onHide(() => dispatch(actionCreators.closeIntercom()));
  }, []);

  useEffect(() => {
    if (!hasBooted && user) {
      intercomApp.boot(user.name, user.email, user.coreId, user.intercomIdentifier);
      setHasBooted(true);
    }
  }, [user]);

  useEffect(() => {
    intercomApp.update();
  }, [location.pathname]);

  return null;
};

export default Intercom;
