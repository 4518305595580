import axios from 'src/lib/axios/gateway-axios';


const getOne = async (id) => {
  const { data } = await axios.get(`/monetize/v1/users/${id}`);
  const { data: context } = await axios.get(`/unity/legacy/v1/users/${id}/context`);
  data._roles = context.user.roles; // eslint-disable-line no-underscore-dangle
  return { ...data, $id: id };
};

const update = async (userId, userData) => {
  const { data } = await axios.put(`/monetize/v1/users/${userId}`, userData);
  let context;
  if (userData && userData.selectedOrganization) {
    const { data: contextResponse } = await axios.patch(`/unity/legacy/v1/users/${userId}/context/organizations/${userData.selectedOrganization}`);
    context = contextResponse;
  } else {
    const { data: contextResponse } = await axios.get(`/unity/legacy/v1/users/${userId}/context`);
    context = contextResponse;
  }
  data._roles = context.user.roles; // eslint-disable-line no-underscore-dangle
  return { ...data, $id: userId };
};

export default {
  getOne,
  update,
};
