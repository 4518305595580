import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';


export const LocaleProvider = (props) => (
  <IntlProvider locale="en">
    {props.children}
  </IntlProvider>
);

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleProvider;
