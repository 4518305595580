import { createPathFor, getRouteByPathname } from 'src/app/iframe/iframe-lib';
import routes from 'src/app/routes';
import { getOrganizationHomeRoute } from './home-route-urls';


export const getNextOrganizationRoute = (requiredParams) => {
  const { coreOrganizationId } = requiredParams;
  if (!coreOrganizationId) {
    throw new Error('Invalid coreOrganizationId!');
  }

  const homePage = getOrganizationHomeRoute(coreOrganizationId);
  const currentRoute = getRouteByPathname(routes, window.location.pathname);
  if (!currentRoute.route) {
    return homePage;
  }
  return createPathFor(currentRoute.route.path, requiredParams) || homePage;
};
