import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Typography from '@unity/react-components/typography';
import { withStyles } from '@material-ui/core/styles';
import { defineMessages, injectIntl } from 'react-intl';
import pageBackground from './404-error.png';


export const messages = defineMessages({
  errorType: {
    defaultMessage: '404',
    id: 'error404.errorType',
  },
  errorTitle: {
    defaultMessage: 'Page not found',
    id: 'error404.errorTitle',
  },
  errorMessage: {
    defaultMessage: "The page you're looking for has been moved or doesn't exist",
    id: 'error404.errorMessage',
  },
});

const IMAGE_WIDTH = 494;
const IMAGE_HEIGHT = 290;

const styles = ({ breakpoints, palette, mixins, spacing }) => ({
  root: {
    ...mixins.flex(null, 'center'),
    paddingBottom: spacing(4),
  },
  errorType: {
    color: palette.grey[500],
  },
  error: {
    backgroundImage: `url(${pageBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    textAlign: 'center',
    boxSizing: 'content-box',

    [breakpoints.down('sm')]: {
      backgroundSize: `${IMAGE_WIDTH * 0.6}px, ${IMAGE_HEIGHT * 0.6}px`,
      paddingTop: 120,
    },
    [breakpoints.up('md')]: {
      paddingTop: 200,
    },
  },
});

export const PageNotFound = ({ classes, intl }) => (
  <div className={classes.root}>
    <div className={classes.error}>
      <Typography variant="titleBig" className={classes.errorType}>
        {intl.formatMessage(messages.errorType)}
      </Typography>
      <Typography variant="titleSmall" gutterBottom="medium">
        {intl.formatMessage(messages.errorTitle)}
      </Typography>
      <Typography variant="bodyStrong">
        {intl.formatMessage(messages.errorMessage)}
      </Typography>
    </div>
  </div>
);

PageNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles, { name: 'PageNotFound' }),
)(PageNotFound);
