export const UPDATE_CURRENT_BIDDER = 'UPDATE_CURRENT_BIDDER';

const actionCreators = {
  updateCurrentBidder: (bidder) => ({ type: UPDATE_CURRENT_BIDDER, bidder }),
};

export default {
  actionCreators,
  actionTypes: {
    UPDATE_CURRENT_BIDDER,
  },
};
