import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import jsCookie from 'js-cookie';


const VERSION_COOKIE_NAME = 'frontendVersion';
const versionCookie = jsCookie.get(VERSION_COOKIE_NAME);

export const initSentry = () => {
  try {
    if (__NODE_ENV__ === 'production' && !versionCookie) {
      Sentry.init({
        dsn: 'https://d43cdf3c1bb24984b0c96a1b99fa61f0@o488710.ingest.sentry.io/5820785',
        environment: window.location.hostname,
        release: __GIT_COMMIT__,
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.05,
        beforeSend(event, hint) {
          const error = hint.originalException;
          // discard axios api errors
          if (error?.isAxiosError) {
            return null;
          }
          return event;
        },
      });
    }
  } catch (e) {
    console.error(e.message);
  }
};

export * from '@sentry/react';
