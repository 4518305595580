import { Component } from 'react';
import once from 'lodash/once';
import { compose } from 'redux';
import { withRouter, matchPath } from 'react-router-dom';
import { shape, string, array } from 'prop-types';
import { connect } from 'react-redux';
import userSelectors from 'src/store/entities/user/user-selectors';
import amplitude, { GROUPS, USER_PROPERTIES, amplitudeEvents } from 'src/lib/amplitude/amplitude';
import { ADMIN_ROLES } from 'src/constants/app-constants';
import routes from 'src/app/routes';


const adminRoles = Object.values(ADMIN_ROLES);

const isGtagAvailable = () => typeof window.gtag === 'function';

const sendGtagPageView = (pathname) => {
  if (isGtagAvailable()) {
    window.gtag('config', 'UA-67298264-2', {
      page_path: pathname,
      page_location: pathname,
    });
  }
};

export const setUserProperties = (amplitudeDI, user) => {
  const identifyObject = new amplitudeDI.Identify();
  const amplitudeInstance = amplitudeDI.getInstance();

  const isAdmin = user._roles.some((role) => adminRoles.includes(role));
  const organizationNames = user.organizations.map((organization) => organization.name);
  const organizationIds = user.organizations.map((organization) => organization.coreOrganization);

  amplitudeInstance.setUserId(user._id);

  identifyObject
    .set(USER_PROPERTIES.EMAIL, user.email)
    .set(USER_PROPERTIES.ROLE, isAdmin ? 'ADMIN' : 'CUSTOMER')
    .set(USER_PROPERTIES.ORGANIZATIONS, organizationIds)
    .set(USER_PROPERTIES.ORGANIZATION_NAMES, organizationNames)
    .set(USER_PROPERTIES.LAST_SEEN, new Date().toISOString())
    .setOnce(USER_PROPERTIES.FIRST_SEEN, new Date().toISOString());

  if (amplitudeDI.isNewSession()) {
    identifyObject.add(USER_PROPERTIES.NUMBER_OF_SESSIONS, 1);
    amplitudeEvents.newSession();
  }

  amplitudeInstance.identify(identifyObject);
};

export class Analytics extends Component {
  componentDidMount() {
    const { user } = this.props;
    const isUserPresent = user && user._id;

    if (isUserPresent && amplitude.isInitialized) {
      this.setUserPropertiesOnce(amplitude, user);
      this.setUserGroups(amplitude);
    }

    this.sendPageViewEvent();
  }

  componentDidUpdate(prevProps) {
    const currentUser = this.props.user;
    const isUserPresent = currentUser && currentUser._id;
    const isRouteChanged = this.props.location.pathname !== prevProps.location.pathname;

    if (isUserPresent && amplitude.isInitialized) {
      this.setUserPropertiesOnce(amplitude, currentUser);
    }

    if (isRouteChanged) {
      this.setUserGroups(amplitude);
      this.sendPageViewEvent();
    }
  }

  setUserPropertiesOnce = once(setUserProperties);

  setUserGroups(amplitudeDI) {
    const identifyObject = new amplitudeDI.Identify();
    const currentPathname = this.props.location.pathname;
    const amplitudeInstance = amplitudeDI.getInstance();
    const matchingRoute = Object.values(routes).find((route) => matchPath(currentPathname, route));

    if (matchingRoute) {
      const match = matchPath(currentPathname, matchingRoute);
      const { coreOrganizationId } = match.params;

      if (coreOrganizationId) {
        amplitudeInstance.setGroup(GROUPS.ORGANIZATION, coreOrganizationId);
      }

      amplitudeInstance.identify(identifyObject);
    }
  }

  sendPageViewEvent() {
    const currentPathname = this.props.location.pathname;
    const isRootPath = currentPathname === '/';
    const matchingRoute = Object.values(routes).find((route) => matchPath(currentPathname, route));

    if (isRootPath) {
      return;
    }

    if (matchingRoute) {
      const match = matchPath(currentPathname, matchingRoute);
      const matchParamValues = Object.values(match.params);
      const nonUniquePathname = currentPathname
        .split('/')
        .map((part) => (matchParamValues.includes(part) ? '*' : part))
        .join('/');

      amplitudeEvents.pageView({ pathname: nonUniquePathname, isValidRoute: true });
      sendGtagPageView(nonUniquePathname);
    } else {
      amplitudeEvents.pageView({ pathname: currentPathname, isValidRoute: false });
      sendGtagPageView(currentPathname);
    }
  }

  render() {
    return null;
  }
}

Analytics.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  user: shape({
    _id: string.isRequired,
    _roles: array.isRequired,
    email: string.isRequired,
    organizations: array.isRequired,
  }),
};

Analytics.defaultProps = {
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  const user = userSelectors.getCurrentUser(state, ownProps);
  return {
    user: user && user._id ? user : null,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Analytics);
