import reducers from 'src/store/utils/reducers';
import initialState from 'src/store/initial-state';
import { actionTypes } from './organization-actions';


const organizationReducer = (state = initialState.organizations, { type, data }) => {
  switch (type) {
    case actionTypes.getAllSuccess:
      return reducers.handleGetAllSuccess(state, data);
    case actionTypes.getAllError:
      return reducers.handleGetAllError(state);
    case actionTypes.getAllPending:
      return reducers.handleGetAllPending(state);
    case actionTypes.getOneSuccess:
      return reducers.handleGetOneSuccess(state, data);
    case actionTypes.getOneError:
      return reducers.handleGetOneError(state, data);
    case actionTypes.getOnePending:
      return reducers.handleGetOnePending(state, data);
    case actionTypes.updateError:
      return reducers.handleUpdateError(state, data);
    case actionTypes.updatePending:
      return reducers.handleUpdatePending(state, data);
    case actionTypes.updateSuccess:
      return reducers.handleUpdateSuccess(state, data);
    default:
      return state;
  }
};

export default organizationReducer;
