import React from 'react';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import withOpenability from '@unity/react-components/hoc/with-openability';
import HeaderContainer from '@unity/react-components/header/header-container';
import UnityLogo from '@unity/react-components/icons/unity-name-logo';
import HeaderLogo from '@unity/react-labs/app-header/logo';
import LayoutWrapper from 'src/app/layout-wrapper/layout-wrapper';
import Footer from 'src/app/footer/footer';
import Analytics from 'src/app/analytics/analytics';
import { DarkThemeProvider } from '@unity/react-labs/app-header/dark-theme-provider/dark-theme-provider';
import { makeStyles, AppBar } from '@material-ui/core';
import { PublicAppRoutes } from '../app-routes/public-app-routes';


const useStyles = makeStyles(({ spacing, mixins, palette }) => ({
  logo: {
    ...mixins.size('auto', 28),
    marginLeft: spacing(2.5),
    color: palette.common.white,
    lineHeight: 0,
  },
  leftSideContainer: {
    ...mixins.flex('center'),
    flexWrap: 'nowrap',
    height: '100%',
  },
}));

export const PublicPage = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar color="secondary">
        <DarkThemeProvider>
          <HeaderContainer>
            <Grid className={classes.leftSideContainer}>
              <HeaderLogo href="https://unity.com/" logo={UnityLogo} className={classes.logo} />
            </Grid>
          </HeaderContainer>
        </DarkThemeProvider>
      </AppBar>
      <Analytics />
      <LayoutWrapper>
        <main>
          <PublicAppRoutes />
          <Footer />
        </main>
      </LayoutWrapper>
    </>
  );
};

export default compose(
  withOpenability(),
)(PublicPage);
