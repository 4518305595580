import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './admin-user-api';


export const storeCollection = 'admin-users';
export const actionTypes = getActionTypesFor('ADMIN_USERS');
export const actionCreators = getActionCreatorsFor('ADMIN_USERS');
export const apiActionCreators = getApiActions(actionCreators);

const getAllAdminUsers = () => apiActionCreators.getAll({
  forceFetch: true,
  collection: storeCollection,
  request: () => api.getAll(),
});

const actions = {
  getAllAdminUsers,
};

export default actions;
