import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './bidder-api';


export const storeCollection = 'bidders';
export const actionTypes = getActionTypesFor('BIDDERS');
export const actionCreators = getActionCreatorsFor('BIDDERS');
export const apiActionCreators = getApiActions(actionCreators);

const getBidderById = (coreOrganizationId, bidderId) => {
  if (!bidderId) throw new Error('Missing bidderId');
  if (!coreOrganizationId) throw new Error('Missing coreOrganizationId');

  return apiActionCreators.getOne({
    forceFetch: true,
    $id: bidderId,
    collection: storeCollection,
    request: () => api.getOne(coreOrganizationId, bidderId),
  });
};

const actions = {
  getBidderById,
};

export default actions;
