export default [
  {
    name: 'tokenlocal',
    uiDomain: '.dashboard.unity3d.com.localhost',
    cookieDomain: '.dashboard.unity3d.com.localhost',
  }, {
    name: 'tokenstaging',
    uiDomain: 'partner.staging.dashboard.unity3d.com',
    cookieDomain: '.staging.dashboard.unity3d.com',
  }, {
    name: 'token',
    uiDomain: 'partner.dashboard.unity3d.com',
    cookieDomain: '.dashboard.unity3d.com',
  },
];
