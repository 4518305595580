import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import StateCard from '@unity/react-components/state-card';


export const messages = defineMessages({
  accessRequired: {
    id: 'noOrganizationAccessError.accessRequired',
    defaultMessage: 'Organization access required',
  },
  noSufficientPermissions: {
    id: 'noOrganizationAccessError.noSufficientPermissions',
    defaultMessage: "You don't have sufficient permissions to view the data of this Organization. Contact Organization owner to resolve the issue.",
  },
  takeMeHome: {
    id: 'noOrganizationAccessError.takeMeHome',
    defaultMessage: 'Take me home',
  },
});

const handleNavigationToHomePage = () => window.location.assign('/');

export const NoOrganizationAccess = ({ intl, showHomeButton }) => {
  const buttonProps = {
    onClick: handleNavigationToHomePage,
    label: intl.formatMessage(messages.takeMeHome),
  };

  return (
    <StateCard
      title={intl.formatMessage(messages.accessRequired)}
      description={intl.formatMessage(messages.noSufficientPermissions)}
      ButtonProps={showHomeButton ? buttonProps : {}}
    />
  );
};

NoOrganizationAccess.propTypes = {
  intl: PropTypes.object.isRequired,
  showHomeButton: PropTypes.bool.isRequired,
};

export default injectIntl(NoOrganizationAccess);
