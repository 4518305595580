export const LOCAL_DOMAIN = 'partner.dashboard.unity3d.com.localhost';
export const STAGING_DOMAIN = 'partner.staging.dashboard.unity3d.com';
export const PRODUCTION_DOMAIN = 'partner.dashboard.unity3d.com';

export const CURRENT_USER_ID = 'me';

export const PERSIST_KEY = 'root';

export const ORGANIZATION_ROLES = {
  USER_ROLE: 'user',
  MANAGER_ROLE: 'manager',
  OWNER_ROLE: 'owner',
};

export const ORGANIZATION_FEATURES = {
  BIDDERCONFIG: 'configuration',
  PSP: 'partnerServicePortal',
};

export const ADMIN_ROLES = {
  ADMIN: 'ads-partner-admin',
};

export const ALL_ADMIN_ROLES = Object.values(ADMIN_ROLES);

export const FEATURES = Object.values(ORGANIZATION_FEATURES);

export const ROLES = [...Object.values(ORGANIZATION_ROLES), ...Object.values(ADMIN_ROLES)];

export const TIME_UNITS = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
};

export const INITIAL_PAGE_SIZE = 25;

export const PERCENT_FORMAT = {
  style: 'percent',
  maximumFractionDigits: 4,
};
