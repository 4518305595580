export const actionTypes = {
  updateImpressionType: 'UPDATE_IMP_TYPE',
  toggleFeature: 'TOGGLE_FEATURE',
};

export const actionCreators = {
  updateImpressionType: (impressionType) => ({
    type: actionTypes.updateImpressionType,
    impressionType,
  }),
  toggleFeature: (featureKey) => ({
    type: actionTypes.toggleFeature,
    featureKey,
  }),
};
