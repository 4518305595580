import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './user-api';


export const storeCollection = 'users';
export const actionTypes = getActionTypesFor('USERS');
export const actionCreators = getActionCreatorsFor('USERS');
export const apiActionCreators = getApiActions(actionCreators);

const getUser = (userId) => {
  if (!userId) throw new Error('Invalid userId');

  return apiActionCreators.getOne({
    request: () => api.getOne(userId),
    forceFetch: true,
    collection: storeCollection,
    $id: userId,
  });
};

const updateUser = (userId, payload) => {
  if (!userId) throw new Error('Invalid userId');

  return apiActionCreators.update({
    request: () => api.update(userId, payload),
    $id: userId,
  });
};

const actions = {
  getUser,
  updateUser,
};

export default actions;
