export const actionTypes = {
  RESET: 'IMPERSONATOR_RESET',
  SET_ROLES: 'IMPERSONATOR_SET_ROLES',
  SET_FEATURES: 'IMPERSONATOR_SET_FEATURES',
};

export const actionCreators = {
  reset: (data) => ({ type: actionTypes.RESET, data }),
  setRoles: (data) => ({ type: actionTypes.SET_ROLES, data }),
  setFeatures: (data) => ({ type: actionTypes.SET_FEATURES, data }),
};

export default {
  reset: actionCreators.reset,
  setRoles: actionCreators.setRoles,
  setFeatures: actionCreators.setFeatures,
};
