import initialState from 'src/store/initial-state';
import reducers from 'src/store/utils/reducers';
import { actionTypes } from './bidder-actions';


const bidderReducer = (state = initialState.bidders, { type, data }) => {
  switch (type) {
    case actionTypes.getOneSuccess:
      return reducers.handleGetOneSuccess(state, data);
    case actionTypes.getOneError:
      return reducers.handleGetOneError(state, data);
    case actionTypes.getOnePending:
      return reducers.handleGetOnePending(state, data);
    default:
      return state;
  }
};

export default bidderReducer;
