import get from 'lodash/get';
import omit from 'lodash/omit';
import mergeWith from 'lodash/mergeWith';
import { getEntityMetadata } from 'src/store/initial-state';


const isInEntities = ({ $id: entityId }, { entities }) => (
  Object.keys(entities).includes(entityId)
);

// eslint-disable-next-line max-len
export const mergeCopyArrays = (objValue, srcValue) => (Array.isArray(objValue) ? srcValue : undefined);

const handleCreatePending = (state) => ({
  ...state,
  isCreating: true,
  createError: false,
});

const handleCreateError = (prevState, data) => ({
  ...prevState,
  isCreating: false,
  createError: get(data, 'error.apiError', true),
});

const handleCreateSuccess = (prevState, newEntity) => {
  const lastUpdated = Date.now();
  return {
    ...prevState,
    isCreating: false,
    entities: {
      ...prevState.entities,
      [newEntity.$id]: {
        ...newEntity,
        metadata: {
          ...getEntityMetadata(),
          lastUpdated,
        },
      },
    },
  };
};

const handleDeletePending = (prevState, data) => {
  const entity = prevState.entities[data.$id] || {};
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [data.$id]: {
        ...entity,
        metadata: {
          ...entity.metadata,
          isDeleting: true,
          deleteError: false,
        },
      },
    },
  };
};

const handleDeleteError = (prevState, data) => {
  const entity = prevState.entities[data.$id] || {};
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [data.$id]: {
        ...entity,
        metadata: {
          ...entity.metadata,
          isDeleting: false,
          deleteError: get(data, 'error.apiError', true),
        },
      },
    },
  };
};

const handleDeleteSuccess = (prevState, data) => ({
  ...prevState,
  entities: omit(prevState.entities, data.$id),
});

const handleGetAllError = (prevState, data) => ({
  ...prevState,
  isFetching: false,
  fetchError: get(data, 'error.apiError', true),
});

const handleGetAllPending = (state) => ({
  ...state,
  isFetching: true,
  fetchError: false,
});

const handleGetAllSuccess = (prevState, newData) => {
  const lastUpdated = Date.now();

  return {
    ...prevState,
    lastUpdated,
    isFetching: false,
    entities: {
      ...prevState.entities,
      ...newData.reduce((entities, newEntityData) => {
        const oldEntityData = prevState.entities[newEntityData.$id] || {};
        return {
          ...entities,
          [newEntityData.$id]: {
            ...mergeWith({}, oldEntityData, newEntityData, mergeCopyArrays),
            metadata: {
              ...getEntityMetadata(),
              lastUpdated,
            },
          },
        };
      }, {}),
    },
  };
};

const handleGetOneError = (prevState, data) => {
  const entity = prevState.entities[data.$id] || {};
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [data.$id]: {
        ...entity,
        metadata: {
          ...entity.metadata,
          isFetching: false,
          fetchError: get(data, 'error.apiError', true),
        },
      },
    },
  };
};

const handleGetOnePending = (prevState, id) => {
  const entity = prevState.entities[id] || {};
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [id]: {
        ...entity,
        metadata: {
          ...entity.metadata,
          isFetching: true,
          fetchError: false,
        },
      },
    },
  };
};

const handleGetOneSuccess = (prevState, newEntity) => {
  const lastUpdated = Date.now();
  const oldEntity = prevState.entities[newEntity.$id] || {};
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [newEntity.$id]: {
        ...mergeWith({}, oldEntity, newEntity, mergeCopyArrays),
        metadata: {
          ...getEntityMetadata(),
          isFetching: false,
          lastUpdated,
        },
      },
    },
  };
};

const handleUpdateError = (prevState, data) => {
  if (isInEntities(data, prevState)) {
    const oldEntity = prevState.entities[data.$id];
    return {
      ...prevState,
      entities: {
        ...prevState.entities,
        [data.$id]: {
          ...oldEntity,
          metadata: {
            ...oldEntity.metadata,
            isUpdating: false,
            updateError: get(data, 'error.apiError', true),
          },
        },
      },
    };
  }

  return prevState;
};

const handleUpdatePending = (prevState, newEntity) => {
  if (isInEntities(newEntity, prevState)) {
    const oldEntity = prevState.entities[newEntity.$id];
    return {
      ...prevState,
      entities: {
        ...prevState.entities,
        [newEntity.$id]: {
          ...oldEntity,
          metadata: {
            ...oldEntity.metadata,
            isUpdating: true,
            updateError: false,
          },
        },
      },
    };
  }

  return prevState;
};

const handleUpdateSuccess = (prevState, newEntity) => {
  const lastUpdated = Date.now();
  if (isInEntities(newEntity, prevState)) {
    const oldEntity = prevState.entities[newEntity.$id];
    return {
      ...prevState,
      entities: {
        ...prevState.entities,
        [newEntity.$id]: {
          ...mergeWith({}, oldEntity, newEntity, mergeCopyArrays),
          metadata: {
            ...oldEntity.metadata,
            isUpdating: false,
            lastUpdated,
          },
        },
      },
    };
  }

  return prevState;
};


export default {
  handleCreateError,
  handleCreatePending,
  handleCreateSuccess,
  handleDeleteError,
  handleDeletePending,
  handleDeleteSuccess,
  handleGetAllError,
  handleGetAllPending,
  handleGetAllSuccess,
  handleGetOneError,
  handleGetOnePending,
  handleGetOneSuccess,
  handleUpdateError,
  handleUpdatePending,
  handleUpdateSuccess,
};
