export const IMPRESSION_TYPES = {
  DEFAULT: '',
  VIDEO_VAST: 'VAST-VPAID',
  BANNER: 'Banner',
  MRAID_PLAYABLE: 'MRAID Playable',
  MRAID_DISPLAY: 'MRAID Display',
};

export const FEATURES = {
  ENABLE_GDPR: 'Enable GDPR',
  ENABLE_COPPA: 'Enable COPPA',
  ENABLE_CONTEXTUAL_DATA: 'Enable Contexual Data',
  ENABLE_PMP: 'Enable PMP',
  ENABLE_BLOCKED_DATA: 'Enable Blocked Data',
  ENABLE_REWARDED: 'Enable Rewarded',
};

export const FEATURES_KEY_MAP = {
  [FEATURES.ENABLE_GDPR]: 'enableGDPR',
  [FEATURES.ENABLE_COPPA]: 'enableCOPPA',
  [FEATURES.ENABLE_CONTEXTUAL_DATA]: 'enableContextualData',
  [FEATURES.ENABLE_PMP]: 'enablePMP',
  [FEATURES.ENABLE_BLOCKED_DATA]: 'enableBlockedData',
  [FEATURES.ENABLE_REWARDED]: 'enableRewarded',
};

export const SAMPLE_BID_REQUEST_BANNER = {
  id: 'bcda7938-14eb-4a46-8dcb-bcd7587a964f',
  imp: [{
    id: '1',
    banner: {
      w: 320,
      h: 50,
      battr: [1, 3, 5, 6, 8, 9, 13],
    },
    pmp: {},
    displaymanager: 'unity sdk',
    displaymanagerver: '3200',
    tagid: 'com.unity3d.ads.opstool-banner',
    secure: 1,
  }],
  app: {
    id: '10d8f2d33850b0a1465359fe129a342e',
    bundle: 'com.unity3d.ads.opstool',
  },
  device: {
    ua: 'Mozilla/5.0 (Linux; Android 9; Pixel Build/PQ3A.190705.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/75.0.3770.101 Mobile Safari/537.36',
    geo: {
      lat: 37.7958,
      lon: -122.4203,
      type: 2,
      country: 'USA',
      region: 'CA',
      city: 'San Francisco',
      utcoffset: -480,
    },
    ip: '4.14.109.3',
    devicetype: 4,
    make: 'Google',
    model: 'Pixel',
    os: 'android',
    osv: '9.0',
    hwv: 'Google Pixel',
    w: 320,
    h: 50,
    language: 'en',
    connectiontype: 2,
    ifa: 'd0a77d1b-e974-41f3-84c5-8fe75718ef2d',
  },
  at: 2,
  tmax: 500,
  regs: {
    ext: {},
  },
  ext: {
    sessionDepth: 1,
  },
};

export const SAMPLE_BID_REQUEST_MRAID_DISPLAY = {
  id: 'bcda7938-14eb-4a46-8dcb-bcd7587a964f',
  imp: [{
    id: '1',
    banner: {
      w: 320,
      h: 480,
      format: [{
        w: 480,
        h: 320,
      }, {
        w: 320,
        h: 480,
      }],
      battr: [1, 3, 5, 6, 8, 9, 13],
      pos: 7,
      api: [5],
    },
    pmp: {},
    displaymanager: 'unity sdk',
    displaymanagerver: '3000',
    instl: 1,
    tagid: 'com.unity3d.unityads.AdOpsTools.enterprise-mraid',
    secure: 1,
  }],
  app: {
    id: '184df635e1a1a850b412dfa33092244e',
  },
  device: {
    ua: 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Mobile/12B411',
    geo: {
      lat: 37.7958,
      lon: -122.4203,
      type: 2,
      country: 'USA',
      region: 'CA',
      city: 'San Francisco',
      utcoffset: -480,
    },
    ip: '4.14.109.2',
    devicetype: 4,
    model: 'iPhone7,1',
    os: 'ios',
    osv: '8.1',
    hwv: 'iPhone 6 Plus',
    h: 716,
    w: 414,
    language: 'en',
    carrier: 'dna',
    connectiontype: 2,
    ifa: '36E493A2-8E81-4CAE-B85A-1B9786E3C9F2',
  },
  at: 2,
  tmax: 500,
  regs: {
    ext: {},
  },
  ext: {
    sessionDepth: 1,
  },
};

export const SAMPLE_BID_REQUEST_MRAID_PLAYABLE = {
  id: 'bcda7938-14eb-4a46-8dcb-bcd7587a964f',
  imp: [{
    id: '1',
    banner: {
      w: 320,
      h: 480,
      format: [{
        w: 480,
        h: 320,
      }, {
        w: 320,
        h: 480,
      }],
      battr: [1, 3, 5, 6, 8, 9],
      pos: 7,
      api: [5],
    },
    pmp: {},
    displaymanager: 'unity sdk',
    displaymanagerver: '3000',
    instl: 1,
    tagid: 'com.unity3d.unityads.AdOpsTools.enterprise-mraid',
    secure: 1,
  }],
  app: {
    id: '184df635e1a1a850b412dfa33092244e',
  },
  device: {
    ua: 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Mobile/12B411',
    geo: {
      lat: 37.7958,
      lon: -122.4203,
      type: 2,
      country: 'USA',
      region: 'CA',
      city: 'San Francisco',
      utcoffset: -480,
    },
    ip: '4.14.109.2',
    devicetype: 4,
    model: 'iPhone7,1',
    os: 'ios',
    osv: '8.1',
    hwv: 'iPhone 6 Plus',
    h: 716,
    w: 414,
    language: 'en',
    carrier: 'dna',
    connectiontype: 2,
    ifa: '36E493A2-8E81-4CAE-B85A-1B9786E3C9F2',
  },
  at: 2,
  tmax: 500,
  regs: {
    ext: {},
  },
  ext: {
    sessionDepth: 1,
  },
};

export const SAMPLE_BID_REQUEST_VIDEO_VAST = {
  id: 'bcda7938-14eb-4a46-8dcb-bcd7587a964f',
  imp: [{
    id: '1',
    video: {
      mimes: ['video/mp4'],
      minduration: 5,
      maxduration: 30,
      protocols: [2, 3, 5, 6],
      w: 320,
      h: 480,
      linearity: 1,
      sequence: 1,
      battr: [1, 3, 5, 6, 8, 9, 13],
      pos: 7,
    },
    pmp: {},
    displaymanager: 'unity sdk',
    displaymanagerver: '3000',
    instl: 1,
    tagid: 'com.unity3d.unityads.AdOpsTools.enterprise-vast',
    secure: 1,
  }],
  app: {
    id: '184df635e1a1a850b412dfa33092244e',
  },
  device: {
    ua: 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Mobile/12B411',
    geo: {
      lat: 37.7958,
      lon: -122.4203,
      type: 2,
      country: 'USA',
      region: 'CA',
      city: 'San Francisco',
      utcoffset: -480,
    },
    ip: '4.14.109.2',
    devicetype: 4,
    model: 'iPhone7,1',
    os: 'ios',
    osv: '8.1',
    hwv: 'iPhone 6 Plus',
    h: 716,
    w: 414,
    language: 'en',
    carrier: 'dna',
    connectiontype: 2,
    ifa: '36E493A2-8E81-4CAE-B85A-1B9786E3C9F2',
  },
  at: 2,
  tmax: 500,
  regs: {
    ext: {},
  },
  ext: {
    sessionDepth: 7,
  },
};

export const SAMPLE_BID_REQUEST_GDPR_ADD_ON = {
  user: {
    ext: {
      consent: '0',
    },
  },
  regs: {
    ext: {
      gdpr: 1,
    },
  },
};

export const SAMPLE_BID_REQUEST_COPPA_ADD_ON = {
  regs: {
    coppa: 1,
  },
};

export const SAMPLE_BID_REQUEST_CONTEXTUAL_DATA_ADD_ON = {
  ext: {
    gameCategory: 'Games',
    subGameCategory: 'Simulation',
  },
};

export const SAMPLE_BID_REQUEST_PMP_ADD_ON = {
  imp: [{
    pmp: {
      deals: [
        {
          id: '0000-Unity',
          bidfloor: 5,
          at: 2,
        }],
    },
  }],
};

export const SAMPLE_BID_REQUEST_BLOCKED_DATA_ADD_ON = {
  bapp: ['com.d3p.mpq'],
  bcat: ['IAB23-9', 'IAB23', 'IAB23-4', 'IAB23-3', 'IAB23-10', 'IAB23-2', 'IAB23-8', 'IAB23-7', 'IAB23-6', 'IAB23-1', 'IAB23-5'],
  ext: {
    bage: '17+',
  },
};

export const SAMPLE_BID_REQUEST_REWARDED_ADD_ON = {
  ext: {
    placementType: 'rewarded',
  },
};

export const SAMPLE_BID_REQUESTS = {
  SAMPLE_BID_REQUEST_BANNER,
  SAMPLE_BID_REQUEST_MRAID_PLAYABLE,
  SAMPLE_BID_REQUEST_MRAID_DISPLAY,
  SAMPLE_BID_REQUEST_VIDEO_VAST,
  SAMPLE_BID_REQUEST_GDPR_ADD_ON,
  SAMPLE_BID_REQUEST_COPPA_ADD_ON,
  SAMPLE_BID_REQUEST_CONTEXTUAL_DATA_ADD_ON,
  SAMPLE_BID_REQUEST_PMP_ADD_ON,
  SAMPLE_BID_REQUEST_BLOCKED_DATA_ADD_ON,
  SAMPLE_BID_REQUEST_REWARDED_ADD_ON,
};
