import React from 'react';
import { func, object, string, oneOfType, bool } from 'prop-types';
import { Link, matchPath as reactRouterMatchPath } from 'react-router-dom';
import SidebarLink from '@unity/react-components/sidebar/sidebar-link';


const getNewTabLink = (to, props) => (<Link target="_blank" rel="noopener noreferrer" to={to} {...props} />);

const getInternalLink = (to, props) => (<Link to={to} {...props} />);

const getLink = (openNewTab, to, props) => (
  openNewTab ? getNewTabLink(to, props) : getInternalLink(to, props)
);

const CustomSidebarLink = ({ to, matchPath, openNewTab, ...rest }) => {
  const pathToMatch = matchPath || to;
  const matchOptions = { path: pathToMatch, exact: true, strict: false };
  const isActive = reactRouterMatchPath(window.location.pathname, matchOptions) !== null;

  return (
    <SidebarLink
      isActive={isActive}
      component={(props) => getLink(openNewTab, to, props)}
      {...rest}
    />
  );
};

CustomSidebarLink.propTypes = {
  text: string.isRequired,
  to: string.isRequired,
  icon: oneOfType([object, func]),
  matchPath: string,
  openNewTab: bool,
};

CustomSidebarLink.defaultProps = {
  icon: null,
  matchPath: null,
  openNewTab: false,
};

export default CustomSidebarLink;
