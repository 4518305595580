import { createTransform } from 'redux-persist';
import entityTTL from '../entity-ttl';
import { getEntityCollection } from '../initial-state';


const entityIsInPendingOrErrorState = ({ metadata }) => {
  const isPending = metadata.isFetching || metadata.isUpdating || metadata.isDeleting;
  const hasError = metadata.fetchError || metadata.updateError || metadata.deleteError;
  return isPending || hasError;
};

const removeInvalidDataAndResetCollectionStates = (state, collectionName) => {
  const timeToLive = entityTTL[collectionName];

  const entities = Object.entries(state.entities).reduce((acc, [entityId, entity]) => {
    const isOutdated = (Date.now() - entity.metadata.lastUpdated) >= timeToLive;
    const isInvalidState = entityIsInPendingOrErrorState(entity);

    if (isOutdated || isInvalidState) {
      return acc;
    }

    return Object.assign(acc, { [entityId]: entity });
  }, {});

  return {
    ...getEntityCollection(),
    entities,
  };
};

const persistTransformer = createTransform(
  removeInvalidDataAndResetCollectionStates,
  removeInvalidDataAndResetCollectionStates,
  { whitelist: Object.keys(entityTTL) },
);

export default persistTransformer;
