import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as NavLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import VersionIcon from '@material-ui/icons/Whatshot';
import Typography from '@unity/react-components/typography';
import AdminHomeButton from '@unity/react-labs/admin-header/admin-home-button/admin-home-button';
import DarkThemeProvider from '@unity/react-labs/app-header/dark-theme-provider';
import IconButton from '@unity/react-components/icon-button';
import ResultListHeader from '@unity/react-labs/admin-header/admin-search/result-list-header';
import ResultListItem from '@unity/react-labs/admin-header/admin-search/result-list-item';
import AdminHeader from '@unity/react-labs/admin-header';
import AdminSearch from '@unity/react-labs/admin-header/admin-search';
import axios from 'src/lib/axios/gateway-axios';
import { getNextOrganizationRoute } from 'src/lib/url-creator/url-creator';
import adminBarActions from 'src/store/ui/admin-bar/admin-bar-actions';
import Impersonator from 'src/app/header/components/impersonator';


const styles = ({ spacing, palette }) => ({
  iconContainer: {
    marginRight: spacing(1),
  },
  // Dark theme overrides
  link: {
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      color: palette.primary.main,
      textDecoration: 'none',
    },
  },
});

const linkToOrganizationResult = (coreOrganizationId) => getNextOrganizationRoute({
  coreOrganizationId,
});

export class AdminBar extends React.Component {
  state = {
    searchValue: '',
  };

  componentDidMount() {
    this.props.actions.openAdminBar();
  }

  componentWillUnmount() {
    this.props.actions.closeAdminBar();
  }

  handleSearch = () => {
    const { searchValue } = this.state;
    if (!searchValue) {
      return null;
    }
    return this.search(searchValue);
  };

  search = async (searchPhrase) => {
    try {
      const { data } = await axios.get(`/search/v1/entities?collections=organizations&limit=3&offset=0&query=${searchPhrase}`);
      return data && data.organizations.results.map((organization) => organization.data);
    } catch (err) {
      return null;
    }
  };

  handleChange = (searchValue) => {
    this.setState({ searchValue });
  };

  // Prevents result dropdown from closing when clicking on "no search results" etc.
  handlePreventPrematurePopperClosing = (event) => {
    event.preventDefault();
  };

  // Mimics current admin bar implementation in Operate, where search yields results
  // for multiple categories of objects in a single query, e.g. projects, users & organizations
  renderResults = ({ results }) => {
    const hasNoOrganizations = !results || results.length === 0;
    return (
      <>
        <List>
          <ResultListHeader>Organizations</ResultListHeader>
          {hasNoOrganizations && (
            <ListItem onClick={this.handlePreventPrematurePopperClosing}>
              No results
            </ListItem>
          )}
          {!hasNoOrganizations && results.map(({ coreOrganization, name }) => (
            <ResultListItem
              key={coreOrganization}
              to={linkToOrganizationResult(coreOrganization)}
              component={NavLink}
            >
              {name}
            </ResultListItem>
          ))}
        </List>
      </>
    );
  };

  render() {
    const actionsEl = this.props.location.pathname.includes('/admin')
      ? (
        <>
          <Impersonator />
        </>
      )
      : (
        <>
          <div>
            <IconButton>
              <VersionIcon />
            </IconButton>
            <Impersonator />
          </div>
          <AdminSearch
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            placeholder="Search..."
            searchValue={this.state.searchValue}
          >
            {this.renderResults}
          </AdminSearch>
        </>
      );

    return (
      <DarkThemeProvider>
        <AdminHeader
          actions={actionsEl}
          homeButton={(
            <AdminHomeButton to="/admin" component={NavLink} />
          )}
          breadcrumbs={(
            <CardContent>
              <Typography variant="titleBig">Dashboard Admin</Typography>
            </CardContent>
          )}
          isOpen
        />
      </DarkThemeProvider>
    );
  }
}

AdminBar.propTypes = {
  actions: PropTypes.shape({
    closeAdminBar: PropTypes.func.isRequired,
    openAdminBar: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    openAdminBar: adminBarActions.actionCreators.openAdminBar,
    closeAdminBar: adminBarActions.actionCreators.closeAdminBar,
  }, dispatch),
});

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
  withRouter,
)(AdminBar);
