export const actionTypes = {
  OPEN_INTERCOM: 'OPEN_INTERCOM',
  CLOSE_INTERCOM: 'CLOSE_INTERCOM',
  SET_INTERCOM_MESSAGE_COUNT: 'SET_INTERCOM_MESSAGE_COUNT',
};

export const actionCreators = {
  openIntercom: () => ({ type: actionTypes.OPEN_INTERCOM }),
  closeIntercom: () => ({ type: actionTypes.CLOSE_INTERCOM }),
  setIntercomUnreadMessages: (data) => ({ type: actionTypes.SET_INTERCOM_MESSAGE_COUNT, data }),
};
