import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';


const styles = ({ mixins, palette, spacing }) => ({
  root: {
    lineHeight: 0,
    marginRight: spacing(4),
    width: '210px',
  },
  logo: {
    ...mixins.size('auto', 28),
    color: palette.common.white,
  },
});

export const Logo = (props) => {
  const { classes } = props;
  return (
    <Link to="/" className={classes.root}>
      <SvgIcon viewBox={null} className={classes.logo}>
        <g>
          <g transform="translate(-20.000000, -15.000000)">
            <g>
              <g transform="translate(20.000000, 15.000000)">
                <path d="M85.9,13.9V20h-2.1V4.4h5.8c1.7,0,3.1,0.4,4,1.3c1,0.9,1.5,2,1.5,3.5c0,1.5-0.5,2.7-1.4,3.5c-0.9,0.8-2.3,1.2-4.1,1.2H85.9z M85.9,12.2h3.7c1.1,0,1.9-0.3,2.5-0.8c0.6-0.5,0.9-1.3,0.9-2.3c0-0.9-0.3-1.7-0.9-2.2c-0.6-0.6-1.4-0.9-2.4-0.9h-3.8V12.2z" />
                <path d="M103.8,20c-0.1-0.2-0.2-0.6-0.3-1.2c-0.9,1-2,1.4-3.3,1.4c-1.1,0-2.1-0.3-2.8-1c-0.7-0.6-1.1-1.5-1.1-2.5c0-1.2,0.5-2.2,1.4-2.8c0.9-0.7,2.2-1,3.9-1h1.9V12c0-0.7-0.2-1.2-0.6-1.7c-0.4-0.4-1-0.6-1.8-0.6c-0.7,0-1.3,0.2-1.8,0.5c-0.5,0.4-0.7,0.8-0.7,1.3h-2c0-0.6,0.2-1.1,0.6-1.7c0.4-0.5,1-1,1.7-1.3c0.7-0.3,1.5-0.5,2.3-0.5c1.3,0,2.4,0.3,3.1,1c0.8,0.7,1.2,1.6,1.2,2.8v5.3c0,1.1,0.1,1.9,0.4,2.5V20H103.8z M100.5,18.5c0.6,0,1.2-0.2,1.8-0.5s1-0.7,1.2-1.3v-2.4h-1.6c-2.4,0-3.7,0.7-3.7,2.1c0,0.6,0.2,1.1,0.6,1.5C99.3,18.3,99.8,18.5,100.5,18.5z" />
                <path d="M113.5,10.2c-0.3,0-0.6-0.1-1-0.1c-1.3,0-2.2,0.6-2.7,1.7V20h-2V8.4h1.9l0,1.3c0.7-1,1.6-1.6,2.8-1.6c0.4,0,0.7,0,0.9,0.2V10.2z" />
                <path d="M117.9,5.6v2.8h2.2v1.5h-2.2v7.2c0,0.5,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3c0.2,0,0.5,0,0.9-0.1V20c-0.5,0.1-1,0.2-1.5,0.2c-0.9,0-1.6-0.3-2-0.8s-0.7-1.3-0.7-2.3V9.9h-2.1V8.4h2.1V5.6H117.9z" />
                <path d="M123.6,8.4l0.1,1.5c0.9-1.1,2-1.7,3.5-1.7c2.5,0,3.7,1.4,3.7,4.2V20h-2v-7.7c0-0.8-0.2-1.5-0.6-1.9s-1-0.6-1.8-0.6c-0.6,0-1.2,0.2-1.7,0.5c-0.5,0.3-0.9,0.8-1.1,1.4V20h-2V8.4H123.6z" />
                <path d="M138,20.2c-1.6,0-2.9-0.5-3.8-1.6c-1-1-1.5-2.4-1.5-4.2v-0.4c0-1.2,0.2-2.2,0.7-3.1c0.4-0.9,1.1-1.6,1.8-2.1c0.8-0.5,1.6-0.8,2.6-0.8c1.5,0,2.7,0.5,3.5,1.5s1.3,2.4,1.3,4.3v0.8h-7.9c0,1.1,0.4,2.1,1,2.8c0.6,0.7,1.5,1.1,2.4,1.1c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.3-1.1l1.2,0.9C141.4,19.5,139.9,20.2,138,20.2z M137.7,9.8c-0.8,0-1.5,0.3-2,0.9c-0.5,0.6-0.9,1.4-1,2.5h5.8V13c-0.1-1-0.3-1.8-0.8-2.3C139.2,10.1,138.5,9.8,137.7,9.8z" />
                <path d="M149.7,10.2c-0.3,0-0.6-0.1-1-0.1c-1.3,0-2.2,0.6-2.7,1.7V20h-2V8.4h1.9l0,1.3c0.7-1,1.6-1.6,2.8-1.6c0.4,0,0.7,0,0.9,0.2V10.2z" />
                <path d="M158,13.9V20h-2.1V4.4h5.8c1.7,0,3.1,0.4,4,1.3s1.5,2,1.5,3.5c0,1.5-0.5,2.7-1.4,3.5c-0.9,0.8-2.3,1.2-4.1,1.2H158zM158,12.2h3.7c1.1,0,1.9-0.3,2.5-0.8c0.6-0.5,0.9-1.3,0.9-2.3c0-0.9-0.3-1.7-0.9-2.2s-1.4-0.9-2.4-0.9H158V12.2z" />
                <path d="M168.2,14.1c0-1.1,0.2-2.2,0.7-3.1s1.1-1.6,1.9-2.1s1.7-0.7,2.7-0.7c1.6,0,2.9,0.5,3.8,1.6s1.5,2.6,1.5,4.4v0.1c0,1.1-0.2,2.1-0.7,3c-0.4,0.9-1.1,1.6-1.9,2.1s-1.7,0.8-2.8,0.8c-1.6,0-2.9-0.5-3.8-1.6s-1.5-2.5-1.5-4.4V14.1z M170.2,14.3c0,1.3,0.3,2.3,0.9,3.1c0.6,0.8,1.4,1.2,2.4,1.2c1,0,1.8-0.4,2.4-1.2c0.6-0.8,0.9-1.9,0.9-3.3c0-1.3-0.3-2.3-0.9-3.1c-0.6-0.8-1.4-1.2-2.4-1.2c-1,0-1.8,0.4-2.4,1.2S170.2,12.9,170.2,14.3z" />
                <path d="M186.2,10.2c-0.3,0-0.6-0.1-1-0.1c-1.3,0-2.2,0.6-2.7,1.7V20h-2V8.4h1.9l0,1.3c0.7-1,1.6-1.6,2.8-1.6c0.4,0,0.7,0,0.9,0.2V10.2z" />
                <path d="M190.5,5.6v2.8h2.2v1.5h-2.2v7.2c0,0.5,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3c0.2,0,0.5,0,0.9-0.1V20c-0.5,0.1-1,0.2-1.5,0.2c-0.9,0-1.6-0.3-2-0.8s-0.7-1.3-0.7-2.3V9.9h-2.1V8.4h2.1V5.6H190.5z" />
                <path d="M201.5,20c-0.1-0.2-0.2-0.6-0.3-1.2c-0.9,1-2,1.4-3.3,1.4c-1.1,0-2.1-0.3-2.8-1c-0.7-0.6-1.1-1.5-1.1-2.5c0-1.2,0.5-2.2,1.4-2.8c0.9-0.7,2.2-1,3.9-1h1.9V12c0-0.7-0.2-1.2-0.6-1.7c-0.4-0.4-1-0.6-1.8-0.6c-0.7,0-1.3,0.2-1.8,0.5c-0.5,0.4-0.7,0.8-0.7,1.3h-2c0-0.6,0.2-1.1,0.6-1.7c0.4-0.5,1-1,1.7-1.3s1.5-0.5,2.3-0.5c1.3,0,2.4,0.3,3.1,1c0.8,0.7,1.2,1.6,1.2,2.8v5.3c0,1.1,0.1,1.9,0.4,2.5V20H201.5z M198.2,18.5c0.6,0,1.2-0.2,1.8-0.5s1-0.7,1.2-1.3v-2.4h-1.6c-2.4,0-3.7,0.7-3.7,2.1c0,0.6,0.2,1.1,0.6,1.5C197,18.3,197.6,18.5,198.2,18.5z" />
                <path d="M207.8,20h-2V3.5h2V20z" />
                <path
                  id="Shape"
                  d="M25,0L13.5,3l-1.7,2.9l-3.4,0L0,14l8.4,8.1h3.4l1.7,2.9l11.5,3L28.1,17l-1.7-2.9l1.7-2.9L25,0z M12.1,6.4l8.8-2.2l-5,8.5H5.8L12.1,6.4z M12.1,21.7l-6.3-6.3h10.1l5,8.5L12.1,21.7z M23.3,22.5l-5-8.5l5-8.5l2.4,8.5L23.3,22.5z"
                />
                <path d="M51,8.3c-1.4-0.1-2.7,0.7-3.3,1.8h-0.1V8.6h-2.7v11.2h2.7v-6.4c0-1.5,1-2.6,2.3-2.6s2.2,0.7,2.2,2.1v6.9h2.7l0-7.3C54.9,10,53.3,8.3,51,8.3z" />
                <path d="M40.8,15.1c0,1.5-0.9,2.5-2.3,2.5c-1.3,0-2.1-0.7-2.1-2v-7h-2.7v7.5c0,2.5,1.4,4.1,3.9,4.1c1.3,0.1,2.5-0.5,3.2-1.6h0.1v1.3h2.6V8.6h-2.8L40.8,15.1z" />
                <polygon id="Rectangle-path" points="56.3,8.6 59.1,8.6 59.1,19.8 56.3,19.8" />
                <polygon points="56.3,5.1 59.1,5.1 59.1,7.3 56.3,7.3" />
                <path d="M73,13.6c-0.4,1-0.7,2.5-0.7,2.5h-0.1c0,0-0.4-1.4-0.7-2.5l-1.8-5.1h-2.9l3,8c0.6,1.7,0.9,2.4,0.9,3c0,0.9-0.5,1.5-1.7,1.5h-1.1v2.3h1.7c2.3,0,3.1-0.9,3.9-3.4l4-11.5h-2.9L73,13.6z" />
                <path d="M64.2,16.5v-6H66v-2h-1.7V5.1h-2.7v3.5h-1.6v2h1.6v6.6c0,2.1,1.6,2.7,3,2.7c0.5,0,1,0,1.5,0v-2.2h-0.7c-0.3,0-0.6-0.1-0.8-0.3S64.2,16.8,64.2,16.5L64.2,16.5z" />
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </Link>
  );
};

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'Logo' })(Logo);
