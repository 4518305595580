import mergeWith from 'lodash/mergeWith';
import initialState, { getEntityMetadata } from 'src/store/initial-state';
import reducers, { mergeCopyArrays } from 'src/store/utils/reducers';
import { actionTypes } from './traffic-segment-actions';
import { actionTypes as bidderActionTypes } from 'src/store/entities/organization-bidder/organization-bidder-actions';
import omit from 'lodash/omit';

// custom delete handler for segments
const handleDeleteSegmentSuccess = (prevState, data) => {
  const lastUpdated = Date.now();
  const { coreOrganizationId, bidderId, $id } = data;
  const updated = omit(prevState.entities[coreOrganizationId]
    .bidders[bidderId].segments, $id);
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [coreOrganizationId]: {
        ...prevState.entities[coreOrganizationId],
        bidders: {
          ...prevState.entities[coreOrganizationId].bidders,
          [bidderId]: {
            ...prevState.entities[coreOrganizationId].bidders[bidderId],
            segments: {
              ...updated,
            },
          },
        },
        metadata: {
          ...prevState.entities[coreOrganizationId].metadata,
          isDeleting: false,
          lastUpdated,
        },
      },
    },
  };
};

export const doesEntityExist = (id, entities) => (
  Object.keys(entities).includes(id)
);

const handleUpdateSegmentSuccess = (prevState, { coreOrganizationId, bidderId, $id, data }) => {
  const { bidders } = prevState.entities[coreOrganizationId];
  if (!bidders
      || !doesEntityExist(bidderId, bidders)
      || !doesEntityExist($id, bidders[bidderId].segments)
  ) {
    return prevState;
  }

  const lastUpdated = Date.now();
  const segment = bidders[bidderId].segments[$id];
  return {
    ...prevState,
    entities: {
      ...prevState.entities,
      [coreOrganizationId]: {
        ...prevState.entities.bidders,
        bidders: {
          [bidderId]: {
            segments: {
              ...bidders[bidderId].segments,
              [$id]: {
                ...mergeWith({}, segment, data, mergeCopyArrays),
              },
            },
          },
        },
        metadata: {
          ...prevState.entities[coreOrganizationId].metadata,
          isUpdating: false,
          lastUpdated,
        },
      },
    },
  };
};

const handleCreateSegmentSuccess = (prevState, { coreOrganizationId, bidderId, $id, data }) => {
  const bidders = prevState.entities[coreOrganizationId]?.bidders || {};
  const segments = bidders[bidderId]?.segments || {};
  const lastUpdated = Date.now();
  return {
    ...prevState,
    isCreating: false,
    entities: {
      ...prevState.entities,
      [coreOrganizationId]: {
        ...bidders,
        bidders: {
          [bidderId]: {
            segments: {
              ...segments,
              [$id]: {
                ...data,
                metadata: {
                  ...getEntityMetadata(),
                  lastUpdated,
                },
              },
            },
          },
        },
      },
    },
  };
};

// custom handler to clear related segments when bidder is deleted
export const handleClearSegmentForBidder = (prevState, data) => {
  const lastUpdated = Date.now();
  const { $id, coreOrganizationId } = data;
  const newState = prevState?.entities[coreOrganizationId]?.bidders
    ? {
      ...prevState,
      entities: {
        ...prevState.entities,
        [coreOrganizationId]: {
          ...prevState.entities[coreOrganizationId],
          bidders: {
            ...omit(prevState.entities[coreOrganizationId].bidders, $id),
          },
          metadata: {
            ...prevState.entities[coreOrganizationId].metadata,
            isDeleting: false,
            lastUpdated,
          },
        },
      },
    }
    : prevState;

  return newState;
};

const bidderSegmentReducer = (state = initialState.bidderSegments, { type, data }) => {
  switch (type) {
    case actionTypes.getAllSuccess:
      return reducers.handleGetAllSuccess(state, data);
    case actionTypes.getAllError:
      return reducers.handleGetAllError(state, data);
    case actionTypes.getAllPending:
      return reducers.handleGetAllPending(state);
    case actionTypes.deleteSegmentSuccess:
      return handleDeleteSegmentSuccess(state, data);
    case actionTypes.deleteError:
      return reducers.handleDeleteError(state, data);
    case actionTypes.deletePending:
      return reducers.handleDeletePending(state, data);
    case actionTypes.updateSegmentSuccess:
      return handleUpdateSegmentSuccess(state, data);
    case actionTypes.updateError:
      return reducers.handleUpdateError(state, data);
    case actionTypes.updatePending:
      return reducers.handleUpdatePending(state, data);
    case actionTypes.createSegmentSuccess:
      return handleCreateSegmentSuccess(state, data);
    case actionTypes.createError:
      return reducers.handleCreateError(state, data);
    case actionTypes.createPending:
      return reducers.handleCreatePending(state, data);
    case bidderActionTypes.deleteSuccess:
      return handleClearSegmentForBidder(state, data);
    default:
      return state;
  }
};

export default bidderSegmentReducer;
