import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import AppBar from '@material-ui/core/AppBar';
import userSelectors from 'src/store/entities/user/user-selectors';
import organizationSelectors from 'src/store/entities/organization/organization-selectors';
import organizationActions from 'src/store/entities/organization/organization-actions';
import userActions from 'src/store/entities/user/user-actions';
import Header from 'src/app/header/header';
import FetchError from 'src/components/errors/fetch-error';
import LayoutWrapper from 'src/app/layout-wrapper/layout-wrapper';
import CenteredContent from 'src/components/centered-content/centered-content';
import NoOrganizationAccess from 'src/components/errors/no-organization-access';
import PageNotFound from 'src/components/errors/error-404';
import Preloader from 'src/components/preloader/preloader';
import NoAccess from 'src/components/errors/no-access';
import Footer from 'src/app/footer/footer';
import Access from 'src/components/access/access';
import { CURRENT_USER_ID, ADMIN_ROLES, ORGANIZATION_ROLES, ORGANIZATION_FEATURES } from 'src/constants/app-constants';
import Analytics from 'src/app/analytics/analytics';
import withOpenability, { openableShape } from '@unity/react-components/hoc/with-openability';
import AppRoutes from 'src/app/app-routes/app-routes';
import AdminMenuIntegration from 'src/app/admin-menu-integration/admin-menu-integration';
import OrganizationMenuIntegration from 'src/app/organization-menu-integration/organization-menu-integration';
import { FORBIDDEN, NOT_FOUND } from 'http-status-codes';
import AdminBar from 'src/app/admin-bar/admin-bar';
import Intercom from 'src/components/intercom/intercom';


export class App extends Component {
  componentDidMount() {
    this.fetchInitialData();
  }

  componentDidUpdate(prevProps) {
    const { match, isAdminPage } = this.props;

    if (isAdminPage) {
      return;
    }

    const currentCoreId = match.params.coreOrganizationId;
    const previousCoreId = prevProps.match.params.coreOrganizationId;
    const hasOrganizationUpdated = currentCoreId && currentCoreId !== previousCoreId;

    if (hasOrganizationUpdated) {
      this.fetchOrganization();
    }
  }

  fetchInitialData = async () => {
    const { actions, isAdminPage } = this.props;

    await actions.getOneUser(CURRENT_USER_ID);

    if (!isAdminPage) {
      this.fetchOrganization();
    }
  };

  fetchOrganization = () => {
    const { actions, match } = this.props;
    const { coreOrganizationId } = match.params;
    actions.getOneOrganization(coreOrganizationId);
  };

  isBaseDataPresent() {
    const { user, organization, isAdminPage } = this.props;
    const isUserDataPresent = user && user._id;

    const isOrganizationDataPresent = organization && organization.coreOrganization;
    return Boolean(isUserDataPresent && (isOrganizationDataPresent || isAdminPage));
  }

  isAlwaysShownError() {
    const { fetchErrorForUser, fetchErrorForOrganization } = this.props;
    const isUserForbidden = Boolean(get(fetchErrorForUser, 'status') === FORBIDDEN);
    const isOrganizationNotFound = Boolean(get(fetchErrorForOrganization, 'status') === NOT_FOUND);
    return isUserForbidden || isOrganizationNotFound;
  }

  renderPageContent = () => {
    const { openable } = this.props;
    return (
      <Access
        data-test-id="app-content"
        roles={[...Object.values(ORGANIZATION_ROLES), ADMIN_ROLES.ADMIN]}
        features={[ORGANIZATION_FEATURES.PSP]}
        unauthorizedContent={(
          <LayoutWrapper fullWidth>
            <NoAccess />
          </LayoutWrapper>
        )}
        authorizedContent={(
          <LayoutWrapper>
            <Route
              path="/admin"
              render={(routerProps) => (
                <AdminMenuIntegration
                  open={openable.open}
                  onClose={openable.handleClose}
                  {...routerProps}
                />
              )}
            />
            <Route
              path="/organizations/:coreOrganizationId/integration"
              render={(routerProps) => (
                <OrganizationMenuIntegration
                  open={openable.open}
                  onClose={openable.handleClose}
                  {...routerProps}
                />
              )}
            />
            <Route
              path="/organizations/:coreOrganizationId/bidders"
              render={(routerProps) => (
                <OrganizationMenuIntegration
                  open={openable.open}
                  onClose={openable.handleClose}
                  {...routerProps}
                />
              )}
            />
            <Route
              path="/organizations/:coreOrganizationId/:bidderId"
              render={(routerProps) => (
                <OrganizationMenuIntegration
                  open={openable.open}
                  onClose={openable.handleClose}
                  {...routerProps}
                />
              )}
            />
            <main>
              <AppRoutes />
              <Footer />
            </main>
          </LayoutWrapper>
        )}
      />
    );
  };

  renderError = (userErrorStatus, organizationErrorStatus) => {
    if (userErrorStatus === FORBIDDEN) {
      return <NoOrganizationAccess showHomeButton />;
    }

    if (organizationErrorStatus === NOT_FOUND) {
      return (
        <LayoutWrapper fullWidth>
          <PageNotFound />
        </LayoutWrapper>
      );
    }

    return (
      <LayoutWrapper fullWidth>
        <FetchError onRetry={this.fetchInitialData} />
      </LayoutWrapper>
    );
  };

  render() {
    const {
      isFetchingUser,
      isFetchingOrganization,
      fetchErrorForUser,
      fetchErrorForOrganization,
      organization,
      openable,
      user,
    } = this.props;
    const hasError = fetchErrorForUser || fetchErrorForOrganization;
    const isFetching = isFetchingUser || isFetchingOrganization;
    const isBaseDataPresent = this.isBaseDataPresent();
    const isAlwaysShownError = this.isAlwaysShownError();

    const isErrorVisible = (hasError && !isBaseDataPresent) || isAlwaysShownError;
    const isContentVisible = !isErrorVisible && isBaseDataPresent;
    const isLoaderVisible = isFetching && !isContentVisible && !isErrorVisible;

    return (
      <>
        <Analytics />
        <Intercom />
        <AppBar color="secondary">
          <Access
            data-test-id="admin-bar"
            roles={[ADMIN_ROLES.ADMIN]}
            authorizedContent={(
              <AdminBar />
            )}
          />

          <Header
            user={{ organizations: [], ...user }}
            isFetching={isFetchingUser && !isBaseDataPresent}
            fetchError={Boolean(fetchErrorForUser) && !isBaseDataPresent}
            organizationName={get(organization, 'name', '')}
            onMobileMenuTogglerClick={openable.handleOpen}
            primaryNavigationOpenable={openable}
          />
        </AppBar>

        {
          // When user request fails, the logic in mapStateToProps() will mark organization's
          // metadata.isFetching as true. Since user request failure means that no organization
          // request will be made, the isFetching flag would never be set back to false.
          // Because of that, for time being we need to show errors regardless of isFetching flag
        }
        {isErrorVisible && this.renderError(
          fetchErrorForUser.status,
          fetchErrorForOrganization.status,
        )}
        {isContentVisible && this.renderPageContent()}
        {isLoaderVisible && (
          <CenteredContent fullHeight>
            <Preloader size={150} />
          </CenteredContent>
        )}
      </>
    );
  }
}

App.propTypes = {
  actions: PropTypes.shape({
    getOneOrganization: PropTypes.func.isRequired,
    getOneUser: PropTypes.func,
  }).isRequired,
  fetchErrorForOrganization: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  fetchErrorForUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isFetchingOrganization: PropTypes.bool.isRequired,
  isFetchingUser: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  openable: openableShape.isRequired,
  isAdminPage: PropTypes.bool,
  organization: PropTypes.object,
  user: PropTypes.object,
};

App.defaultProps = {
  isAdminPage: false,
  organization: null,
  user: null,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getOneUser: userActions.getUser,
    getOneOrganization: organizationActions.getOneOrganization,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => {
  const isAdminPage = ownProps.match.url.includes('/admin');
  const { coreOrganizationId } = ownProps.match.params;

  const user = userSelectors.getCurrentUser(state, ownProps);
  const isFetchingUser = get(user, 'metadata.isFetching', true);
  const fetchErrorForUser = get(user, 'metadata.fetchError', false);

  const organization = organizationSelectors.getOrganizationByCoreId(state, coreOrganizationId);
  const isFetchingOrganization = get(organization, 'metadata.isFetching', !!coreOrganizationId);
  const fetchErrorForOrganization = get(organization, 'metadata.fetchError', false);
  const featureToggles = get(organization, 'featureToggles', {});

  return {
    isFetchingUser,
    isFetchingOrganization,
    featureToggles,
    fetchErrorForUser,
    fetchErrorForOrganization,
    organization,
    user,
    isAdminPage,
  };
};

export default compose(
  withOpenability(),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(App);
