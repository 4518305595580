import initialState from 'src/store/initial-state';
import reducers from 'src/store/utils/reducers';
import { actionTypes } from './user-actions';


const userReducer = (state = initialState.users, { type, data }) => {
  switch (type) {
    case actionTypes.getOneSuccess:
      return reducers.handleGetOneSuccess(state, data);
    case actionTypes.getOneError:
      return reducers.handleGetOneError(state, data);
    case actionTypes.getOnePending:
      return reducers.handleGetOnePending(state, data);
    case actionTypes.updateError:
      return reducers.handleUpdateError(state, data);
    case actionTypes.updatePending:
      return reducers.handleUpdatePending(state, data);
    case actionTypes.updateSuccess:
      return reducers.handleUpdateSuccess(state, data);
    default:
      return state;
  }
};

export default userReducer;
