import { getInitialState as getInitialImpersonatorState } from './ui/impersonator/impersonator-reducer';
import { getInitialState as getInitialSampleBidRequestConfigState } from './ui/sample-bid-request/sample-bid-request-reducer';
import { getInitialState as getInitialBidResponseState } from './ui/bid-response-validator/bid-response-validator-reducer';
import { getInitialState as getInitialUIAdminBarState } from './ui/admin-bar/admin-bar-reducer';
import { getInitialState as getInitialAdminUserPageState } from './ui/admin/user-page-reducer';
import { getInitialState as getInitialSideBarState } from './ui/side-bar/side-bar-reducer';
import { getInitialState as getInitialIntercomState } from './ui/intercom/intercom-reducer';


export const getEntityCollectionMetadata = () => ({
  lastUpdated: null,
  isCreating: false,
  createError: false,
  isFetching: false,
  fetchError: false,
});

export const getEntityCollection = () => ({
  ...getEntityCollectionMetadata(),
  entities: {},
});

export const getEntityMetadata = () => ({
  lastUpdated: null,
  isFetching: false,
  fetchError: false,
  isUpdating: false,
  updateError: false,
  isDeleting: false,
  deleteError: false,
});

const initialState = {
  users: getEntityCollection(),
  adminUsers: getEntityCollection(),
  organizations: getEntityCollection(),
  bidders: getEntityCollection(),
  organizationBidders: getEntityCollection(),
  bidderSegments: getEntityCollection(),
  organizationUsers: getEntityCollection(),
  uiImpersonator: getInitialImpersonatorState(),
  uiSampleBidRequestConfig: getInitialSampleBidRequestConfigState(),
  uiBidResponseAndLog: getInitialBidResponseState(),
  uiAdminBar: getInitialUIAdminBarState(),
  adminUserPage: getInitialAdminUserPageState(),
  sideBar: getInitialSideBarState(),
  intercom: getInitialIntercomState(),
};

export default initialState;
