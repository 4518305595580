import merge from 'lodash/merge';
import createTheme from '@unity/react-components/create-theme';


const partnerDashboardTheme = {
  constants: {
    adminHeaderHeight: 72,
  },
};

const theme = createTheme(
  {
    overrides: {
      MuiTableCell: {
        root: {
          padding: '14px 16px 14px 16px',
        },
      },
    },
  },
);

export default merge(theme, partnerDashboardTheme);
