import pick from 'lodash/pick';
import intersection from 'lodash/intersection';
import { ROLES, FEATURES } from 'src/constants/app-constants';
import { actionTypes as organizationActionTypes } from 'src/store/entities/organization/organization-actions';
import { actionTypes as userActionTypes } from 'src/store/entities/user/user-actions';
import toObject from 'src/lib/to-object/to-object';
import { actionTypes as impersonatorActionTypes } from './impersonator-actions';


export const getInitialState = () => ({
  isImpersonating: false,
  features: FEATURES.reduce((acc, role) => ({ ...acc, [role]: false }), {}),
  roles: ROLES.reduce((acc, role) => ({ ...acc, [role]: false }), {}),
});

const initializeStateWithData = (state, data) => ({
  ...state,
  isImpersonating: false,
  features: {
    ...getInitialState().features,
    ...pick(data.featureToggles, FEATURES),
  },
  roles: {
    ...getInitialState().roles,
    ...toObject(intersection(data._roles, ROLES), () => true),
  },
});

const impersonatorReducer = (state = getInitialState(), { type, data }) => {
  switch (type) {
    case impersonatorActionTypes.SET_ROLES:
      return {
        ...state,
        isImpersonating: true,
        roles: {
          ...state.roles,
          ...data,
        },
      };
    case impersonatorActionTypes.SET_FEATURES:
      return {
        ...state,
        isImpersonating: true,
        features: {
          ...state.features,
          ...data,
        },
      };
    case impersonatorActionTypes.RESET:
      return initializeStateWithData(state, data);
    case organizationActionTypes.getOneSuccess:
      return initializeStateWithData(state, data);
    case userActionTypes.getOneSuccess:
      return initializeStateWithData(state, data);
    default:
      return state;
  }
};

export default impersonatorReducer;
