import axios from 'src/lib/axios/gateway-axios';


const getOne = async (coreOrganizationId, id) => {
  const { data } = await axios.get(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${id}`);
  return { ...data, $id: id };
};

export default {
  getOne,
};
