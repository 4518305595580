import React from 'react';
import { object } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import ContentRow from '@unity/react-components/content-row';
import CustomFooter from '@unity/react-components/footer';
import Link from '@unity/react-components/link';


export const LINKS = {
  LEGAL: 'https://unity3d.com/legal',
  PRIVACY_POLICY: 'https://unity3d.com/legal/privacy-policy',
  COOKIES: 'https://unity3d.com/legal/cookie-policy',
};

export const messages = defineMessages({
  legal: {
    defaultMessage: 'Legal',
    id: 'footer.legal',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    id: 'footer.privacyPolicy',
  },
  cookies: {
    defaultMessage: 'Cookies',
    id: 'footer.cookies',
  },
});

export const Footer = (props) => {
  const { intl } = props;

  return (
    <CustomFooter
      links={(
        <ContentRow spacing={2.5}>
          <Link external color="secondary" href={LINKS.LEGAL}>
            {intl.formatMessage(messages.legal)}
          </Link>
          <Link external color="secondary" href={LINKS.PRIVACY_POLICY}>
            {intl.formatMessage(messages.privacyPolicy)}
          </Link>
          <Link external color="secondary" href={LINKS.COOKIES}>
            {intl.formatMessage(messages.cookies)}
          </Link>
        </ContentRow>
      )}
    />
  );
};

Footer.propTypes = {
  intl: object.isRequired,
};

export default injectIntl(Footer);
