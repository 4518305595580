import { getActionTypesFor, getActionCreatorsFor, getApiActions } from 'src/store/utils/actions';
import api from './organization-bidder-api';


export const storeCollection = 'organizationBidders';
export const actionTypes = getActionTypesFor('ORGANIZATION_BIDDERS');
export const actionCreators = getActionCreatorsFor('ORGANIZATION_BIDDERS');
export const apiActionCreators = getApiActions(actionCreators);

const createBidder = (coreOrganizationId, bidderName, endpoint) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');
  if (!bidderName) throw new Error('Invalid bidderName');
  if (!endpoint) throw new Error('Invalid endpoint');

  return apiActionCreators.create({
    request: () => api.createOne(coreOrganizationId, bidderName, endpoint),
  });
};

const deleteBidder = (coreOrganizationId, bidderID) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');
  if (!bidderID) throw new Error('Invalid bidderID');

  return apiActionCreators.deleteOne({
    $id: bidderID,
    coreOrganizationId,
    request: () => api.deleteOne(coreOrganizationId, bidderID),
  });
};

const getBiddersForOrganization = (coreOrganizationId) => {
  if (!coreOrganizationId) throw new Error('Invalid coreOrganizationId');

  return apiActionCreators.getAll({
    request: () => api.getAll(coreOrganizationId),
  });
};

const updateBidderById = (coreOrganizationId, id, bidder) => {
  if (!id) throw new Error('Missing bidderId');
  if (!coreOrganizationId) throw new Error('Missing coreOrganizationId');
  const { bidderID, ...bidderToSend } = bidder;

  return apiActionCreators.update({
    request: () => api.update(coreOrganizationId, id, bidderToSend),
    $id: id,
  });
};

const actions = {
  createBidder,
  deleteBidder,
  getBiddersForOrganization,
  updateBidderById,
};

export default actions;
