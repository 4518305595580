import isEmpty from 'lodash/isEmpty';


export const shouldFetchEntity = (entity, forceFetch) => {
  if (isEmpty(entity) || !entity.metadata || entity.metadata.fetchError) return true;

  if (entity.metadata.isFetching) {
    return false;
  }

  if (forceFetch) {
    return true;
  }

  return false;
};
