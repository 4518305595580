export const ADMIN_BAR_OPEN = 'ADMIN_BAR_OPEN';
export const ADMIN_BAR_CLOSE = 'ADMIN_BAR_CLOSED';

const actionCreators = {
  openAdminBar: () => ({ type: ADMIN_BAR_OPEN }),
  closeAdminBar: () => ({ type: ADMIN_BAR_CLOSE }),
};

export default {
  actionCreators,
  actionTypes: {
    ADMIN_BAR_OPEN,
    ADMIN_BAR_CLOSE,
  },
};
