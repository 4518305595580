import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import StateCard from '@unity/react-components/state-card';


export const messages = defineMessages({
  title: {
    id: 'errorFetching.title',
    defaultMessage: 'Something went wrong',
  },
  description: {
    id: 'errorFetching.description',
    defaultMessage: "We weren't able to retrieve the resource you requested. Make sure the URL you provided is correct, and that your internet connection is OK.",
  },
  retry: {
    id: 'errorFetching.retry',
    description: 'Label for retry button',
    defaultMessage: 'Retry',
  },
});

export const FetchError = ({ intl, onRetry, className }) => (
  <StateCard
    className={className}
    title={intl.formatMessage(messages.title)}
    description={intl.formatMessage(messages.description)}
    ButtonProps={{
      onClick: onRetry,
      label: intl.formatMessage(messages.retry),
    }}
  />
);

FetchError.propTypes = {
  intl: PropTypes.object.isRequired,
  className: PropTypes.string,
  onRetry: PropTypes.func,
};

FetchError.defaultProps = {
  onRetry: () => window.location.reload(true),
  className: null,
};

export default injectIntl(FetchError);
