import initialState from 'src/store/initial-state';
import reducers from 'src/store/utils/reducers';
import { actionTypes } from './organization-bidder-actions';


const organizationBidderReducer = (state = initialState.organizationBidders, { type, data }) => {
  switch (type) {
    case actionTypes.getAllSuccess:
      return reducers.handleGetAllSuccess(state, data);
    case actionTypes.getAllError:
      return reducers.handleGetAllError(state, data);
    case actionTypes.getAllPending:
      return reducers.handleGetAllPending(state);
    case actionTypes.deleteSuccess:
      return reducers.handleDeleteSuccess(state, data);
    case actionTypes.deleteError:
      return reducers.handleDeleteError(state, data);
    case actionTypes.deletePending:
      return reducers.handleDeletePending(state, data);
    case actionTypes.createSuccess:
      return reducers.handleCreateSuccess(state, data);
    case actionTypes.createError:
      return reducers.handleCreateError(state, data);
    case actionTypes.createPending:
      return reducers.handleCreatePending(state, data);
    case actionTypes.updateSuccess:
      return reducers.handleUpdateSuccess(state, data);
    default:
      return state;
  }
};

export default organizationBidderReducer;
