import axios from 'src/lib/axios/gateway-axios';

// Add $id used in Redux store and coreOrganizationId
const addUniqueId = (bidder, coreOrganizationId) => ({
  ...bidder,
  coreOrganizationId,
  $id: bidder.bidderID,
});

const createOne = async (coreOrganizationId, bidderName, endpoint) => {
  let retval = {};
  const newBidder = {
    bidderName,
    endpoint,
  };

  const { data } = await axios.post(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidder`, newBidder);
  if (data && data._id) {
    retval = { ...newBidder, bidderID: data._id, $id: data._id, coreOrganizationId };
  }
  return retval;
};

const deleteOne = async (coreOrganizationId, bidderID) => {
  const { data } = await axios.delete(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${bidderID}`);
  return data;
};

const getAll = async (coreOrganizationId) => {
  const retval = [];
  const { data } = await axios.get(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders`);

  data.forEach((bidder) => {
    retval.push(addUniqueId(bidder, coreOrganizationId));
  });

  return retval;
};

const update = async (coreOrganizationId, id, bidder) => {
  const { data } = await axios.put(`/monetize/partner/v1/organizations/${coreOrganizationId}/bidders/${id}`, bidder);
  return { ...data, $id: id };
};

export default {
  createOne,
  deleteOne,
  getAll,
  update,
};
