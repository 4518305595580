import React from 'react';
import { JssProvider } from 'react-jss';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LocaleProvider from 'src/app/locale-provider/locale-provider';
import { ORGANIZATION_ROUTE, ADMIN_ROUTE, SUBMIT_ROUTE, SKADNETWORKS_OVERVIEW } from 'src/app/routes';
import App from 'src/app/mainapp/mainapp';
import PublicPage from 'src/app/noauth/public-page';
import { PersistGate } from 'redux-persist/integration/react';
import PartnerAnimations from 'src/app/animations/animations';
import RootRedirect from 'src/app/root-redirect/root-redirect';
import configureStore from './store/store';
import unityAdsTheme from './constants/theme';
import './lib/highcharts/highcharts-styles.css';
import { initSentry } from './lib/sentry/react';
import './index.css';


const mountNode = document.getElementById('root');
const { store, persistor } = configureStore();
const theme = createMuiTheme(unityAdsTheme);

const AppRenderer = () => (
  <JssProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocaleProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <PartnerAnimations />
            <Router>
              <Switch>
                <Route exact path="/" component={RootRedirect} />
                <Route path={SUBMIT_ROUTE} component={PublicPage} />
                <Route path={SKADNETWORKS_OVERVIEW} component={PublicPage} />
                <Route path={ADMIN_ROUTE} component={App} />
                <Route path={ORGANIZATION_ROUTE} component={App} />
                <Route component={RootRedirect} />
              </Switch>
            </Router>
          </MuiThemeProvider>
        </LocaleProvider>
      </PersistGate>
    </Provider>
  </JssProvider>
);

const renderApp = async () => {
  initSentry();
  render(AppRenderer(), mountNode);
};

hot(renderApp());
