import axios from 'src/lib/axios/gateway-axios';


const addUniqueId = (user, id) => ({
  ...user,
  $id: id,
});

const getAll = async () => {
  const { data } = await axios.get('/monetize/partner/v1/users');

  const retval = [];
  if (data.users) {
    data.users.forEach((user) => {
      retval.push(addUniqueId(user, user._id));
    });
  }

  return retval;
};

export default {
  getAll,
};
