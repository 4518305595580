import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AsyncComponent from 'src/components/async-component/async-component';


export const ORGANIZATION_ROUTE = '/organizations/:coreOrganizationId';
export const ADMIN_ROUTE = '/admin';
export const SUBMIT_ROUTE = '/submit';
export const SKADNETWORKS_OVERVIEW = '/skadnetworkids';
export const BIDDER_ID = '/:bidderId';

export const sceneLoader = {
  overview: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'Overview' */'src/scenes/overview/overview')),
  }),
  integrationTools: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'IntegrationTools' */'src/scenes/integration-tools/integration-tools')),
  }),
  settings: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'Settings' */'src/scenes/organization-settings/organization-settings')),
  }),
  bidderConfiguration: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'BidderConfiguration' */'src/scenes/bidder-configuration/bidder-configuration')),
  }),
  bidders: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'Bidders' */'src/scenes/bidders/bidders')),
  }),
  trafficSegments: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'TrafficSegments' */'src/scenes/traffic-segments/traffic-segments')),
  }),
  organizations: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'Organizations' */'src/scenes/admin/organizations')),
  }),
  users: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'Users' */'src/scenes/admin/users')),
  }),
  skadnetwork: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'SKAdNetwork' */'src/scenes/register/skadnetwork')),
  }),
  skadnetworksOverview: AsyncComponent({
    loader: lazy(() => import(/* webpackChunkName: 'SKAdNetworksOverview' */'src/scenes/register/skadnetworks-overview')),
  }),
};

const renderOrganizationRootRedirect = (routerProps) => (
  <Redirect to={`/organizations/${routerProps.match.params.coreOrganizationId}/integration/tools`} />
);

const renderAdminOrganizationsRedirect = () => (
  <Redirect to={`${ADMIN_ROUTE}/organizations`} />
);

const routes = {
  home: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}`,
    component: renderOrganizationRootRedirect,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['partnerServicePortal'],
  },
  admin: {
    exact: true,
    path: `${ADMIN_ROUTE}`,
    component: renderAdminOrganizationsRedirect,
    roles: ['ads-partner-admin'],
    features: [],
  },
  organizations: {
    exact: true,
    path: `${ADMIN_ROUTE}/organizations`,
    component: sceneLoader.organizations,
    roles: ['ads-partner-admin'],
    features: [],
  },
  users: {
    exact: true,
    path: `${ADMIN_ROUTE}/users`,
    component: sceneLoader.users,
    roles: ['ads-partner-admin'],
    features: [],
  },
  integration: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/integration`,
    component: renderOrganizationRootRedirect,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['partnerServicePortal'],
  },
  overview: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/integration/overview`,
    component: sceneLoader.overview,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['partnerServicePortal'],
  },
  integrationTools: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/integration/tools`,
    component: sceneLoader.integrationTools,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['partnerServicePortal'],
  },
  settings: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/integration/settings`,
    component: sceneLoader.settings,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['partnerServicePortal'],
  },
  bidderConfiguration: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/bidders${BIDDER_ID}/configuration`,
    component: sceneLoader.bidderConfiguration,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['configuration'],
  },
  bidders: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/bidders`,
    component: sceneLoader.bidders,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['configuration'],
  },
  trafficSegments: {
    exact: true,
    path: `${ORGANIZATION_ROUTE}/bidders${BIDDER_ID}/segments`,
    component: sceneLoader.trafficSegments,
    roles: ['user', 'manager', 'owner', 'ads-partner-admin'],
    features: ['configuration'],
  },
};

export const publicRoutes = {
  skadnetwork: {
    exact: true,
    path: `${SUBMIT_ROUTE}/skadnetwork`,
    component: sceneLoader.skadnetwork,
    roles: [],
    features: [],
  },
  skadnetworkOverview: {
    exact: true,
    path: SKADNETWORKS_OVERVIEW,
    component: sceneLoader.skadnetworksOverview,
    roles: [],
    features: [],
  },
};

export default routes;
