import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import PageNotFound from 'src/components/errors/error-404';
import NoAccess from 'src/components/errors/no-access';
import Access from 'src/components/access/access';
import routes from 'src/app/routes';


export const AppRoutes = () => (
  <Switch>
    {Object.entries(routes).map(([name, routeProps]) => (
      <Route
        key={name}
        path={routeProps.path}
        exact={routeProps.exact}
        render={(routerProps) => (
          <Access
            roles={routeProps.roles}
            features={routeProps.features}
            authorizedContent={<Route {...routerProps} {...routeProps} />}
            unauthorizedContent={<NoAccess showHomeButton />}
          />
        )}
      />
    ))}
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default withRouter(AppRoutes);
