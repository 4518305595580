import { actionTypes } from './intercom-actions';


export const getInitialState = () => ({
  isIntercomOpen: false,
  intercomMessageCount: 0,
});

const intercomReducer = (state = getInitialState(), { type, data }) => {
  switch (type) {
    case actionTypes.OPEN_INTERCOM:
      return { ...state, isIntercomOpen: true };
    case actionTypes.CLOSE_INTERCOM:
      return { ...state, isIntercomOpen: false };
    case actionTypes.SET_INTERCOM_MESSAGE_COUNT:
      return { ...state, intercomMessageCount: data };
    default:
      return state;
  }
};

export default intercomReducer;
