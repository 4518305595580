import axios from 'src/lib/axios/gateway-axios';


const addOrganizationId = (users, id) => ([{
  $id: id,
  users,
}]);

const getAll = async (coreOrganizationId) => {
  const { data } = await axios.get(`/monetize/v1/organizations/${coreOrganizationId}/users`);
  return addOrganizationId(data, coreOrganizationId);
};

export default {
  getAll,
};
