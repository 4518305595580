import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'src/components/preloader/preloader';


const AsyncComponent = ({ loader: SceneComponent, preloader }) => (props) => {
  const ScenePreloader = preloader || Preloader;
  return (
    <React.Suspense fallback={<ScenePreloader />}>
      <SceneComponent {...props} />
    </React.Suspense>
  );
};

AsyncComponent.propTypes = {
  loader: PropTypes.func.isRequired,
  preloader: PropTypes.func,
};

AsyncComponent.defaultProps = {
  preloader: Preloader,
};

export default AsyncComponent;
