import { actionTypes } from './sample-bid-request-actions';
import { IMPRESSION_TYPES } from 'src/constants/sample-bid-requests';


export const getInitialState = () => ({
  enableGDPR: false,
  enableCOPPA: false,
  enableContextualData: false,
  enablePMP: false,
  enableBlockedData: false,
  enableRewarded: false,
  impressionType: IMPRESSION_TYPES.VIDEO_VAST,
});

const handleUpdateImpressionType = (prevState, impressionType) => {
  if (impressionType === IMPRESSION_TYPES.DEFAULT) {
    return getInitialState();
  }

  return ({
    ...prevState,
    impressionType,
  });
};

const handleToggleFeature = (prevState, featureKey) => ({
  ...prevState,
  [featureKey]: !prevState[featureKey],
});

export const reducers = {
  handleUpdateImpressionType,
  handleToggleFeature,
};

const sampleBidRequestConfigReducer = (state = getInitialState(),
  { type, impressionType, featureKey }) => {
  switch (type) {
    case actionTypes.updateImpressionType:
      return reducers.handleUpdateImpressionType(state, impressionType);
    case actionTypes.toggleFeature:
      return reducers.handleToggleFeature(state, featureKey);
    default:
      return state;
  }
};

export default sampleBidRequestConfigReducer;
